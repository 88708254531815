const Pathname = {
  __root: "/",
  login: "/login",
  logout: "/logout",
  agreement: "/agreement",
  guide: "/guide",
  guide_backoffice: "/guide/backoffice",
  maintenance: "/maintenance",
  webfront: "/",
  webfront_service: "/service",
  webfront_service_id: "/service/:id",
  webfront_service_id_type: "/service/:id/:type",
  webfront_print: "/print",
  webfront_print_service: "/print/service",
  webfront_print_service_list: "/print/service/list",
  webfront_print_service_details: "/print/service/details",
  webfront_print_service_map: "/print/service/map",
  webfront_print_service_id: "/print/service/:id",
  webfront_preview_jigyosho_id: "/preview/jigyosho/:id",
  webfront_preview_jigyosho_id_type: "/preview/jigyosho/:id/:type",
  webfront_preview_agent_collection_id: "/preview/agent-collection/:id",
  webfront_preview_agent_collection_id_type:
    "/preview/agent-collection/:id/:type",
  webfront_preview_print_service_id: "/preview/print/service/:id",
  webfront_folder: "/folder",
  webfront_folder_widget: "/folder/widget",
  webfront_search: "/search",
  webfront_search_condition: "/search/condition",
  webfront_information: "/information",
  webfront_information_id: "/information/:id",

  backoffice: "/backoffice",
  backoffice_hojin: "/backoffice/hojin",
  backoffice_hojin_add: "/backoffice/hojin/add",
  backoffice_hojin_id: "/backoffice/hojin/:id",
  backoffice_hojin_id_edit: "/backoffice/hojin/:id/edit",
  backoffice_hojin_id_features: "/backoffice/hojin/:id/features",
  backoffice_hojin_accounts_id_edit: "/backoffice/hojin/accounts/:id/edit",
  backoffice_hojin_accounts_id_password:
    "/backoffice/hojin/accounts/:id/password",
  backoffice_jigyosho: "/backoffice/jigyosho",
  backoffice_jigyosho_join: "/backoffice/jigyosho/join",
  backoffice_jigyosho_add: "/backoffice/jigyosho/add",
  backoffice_jigyosho_id: "/backoffice/jigyosho/:id",
  backoffice_jigyosho_id_status: "/backoffice/jigyosho/:id/status",
  backoffice_jigyosho_id_change_hojin: "/backoffice/jigyosho/:id/change-hojin",
  backoffice_jigyosho_id_photo: "/backoffice/jigyosho/:id/photo",
  backoffice_jigyosho_id_flier: "/backoffice/jigyosho/:id/flier",
  backoffice_jigyosho_id_attributes: "/backoffice/jigyosho/:id/attributes",
  backoffice_jigyosho_id_attributes_type:
    "/backoffice/jigyosho/:id/attributes/:type",
  backoffice_jigyosho_kanriaccount: "/backoffice/jigyosho/kanriaccount",
  backoffice_jigyosho_caremanagers: "/backoffice/jigyosho/caremanagers",
  backoffice_jigyosho_caremanagers_add: "/backoffice/jigyosho/caremanagers/add",
  backoffice_jigyosho_caremanagers_id: "/backoffice/jigyosho/caremanagers/:id",
  backoffice_jigyosho_caremanagers_id_edit:
    "/backoffice/jigyosho/caremanagers/:id/edit",
  backoffice_jigyosho_caremanagers_id_password:
    "/backoffice/jigyosho/caremanagers/:id/password",
  backoffice_carestaff: "/backoffice/carestaff",
  backoffice_carestaff_add: "/backoffice/carestaff/add",
  backoffice_carestaff_id_edit: "/backoffice/carestaff/:id/edit",
  backoffice_carestaff_id_password: "/backoffice/carestaff/:id/password",
  backoffice_jigyosho_accounts_id_edit:
    "/backoffice/jigyosho/accounts/:id/edit",
  backoffice_jigyosho_accounts_id_password:
    "/backoffice/jigyosho/accounts/:id/password",
  backoffice_agent_collection: "/backoffice/agent-collection",
  backoffice_agent_collection_add: "/backoffice/agent-collection/add",
  backoffice_agent_collection_id: "/backoffice/agent-collection/:id",
  backoffice_agent_collection_id_status:
    "/backoffice/agent-collection/:id/status",
  backoffice_agent_collection_id_photo:
    "/backoffice/agent-collection/:id/photo",
  backoffice_agent_collection_id_flier:
    "/backoffice/agent-collection/:id/flier",
  backoffice_agent_collection_id_attributes:
    "/backoffice/agent-collection/:id/attributes",
  backoffice_agent_collection_id_attributes_type:
    "/backoffice/agent-collection/:id/attributes/:type",
  backoffice_information: "/backoffice/information",
  backoffice_information_id: "/backoffice/information/:id",
  backoffice_notification: "/backoffice/notification",
  backoffice_notification_id: "/backoffice/notification/:id",
  backoffice_news: "/backoffice/news",
  backoffice_news_id: "/backoffice/news/:id",
  backoffice_pickup_id: "/backoffice/news/pickup/:id",
  backoffice_job_offer: "/backoffice/job-offer",
  backoffice_job_offer_id: "/backoffice/job-offer/:id",
  backoffice_job_offer_id_edit: "/backoffice/job-offer/:id/edit",
  backoffice_job_offer_id_reject: "/backoffice/job-offer/:id/reject",
  backoffice_job_offer_jigyosho_id:
    "/backoffice/job-offer/jigyosho/:jigyoshoId",
  backoffice_job_offer_jigyosho_id_photos:
    "/backoffice/job-offer/jigyosho/:jigyoshoId/photos",
  backoffice_job_offer_jigyosho_id_add:
    "/backoffice/job-offer/jigyosho/:jigyoshoId/add",
  tools: "/tools",
  tools_ndxr: "/tools/ndxr",
  tools_jigyosho_csv: "/tools/jigyosho-csv",
  tools_sysop: "/tools/sysop",
  tools_sysop_add: "/tools/sysop/add",
  tools_sysop_id: "/tools/sysop/:id",
  tools_sysop_id_edit: "/tools/sysop/:id/edit",
  tools_sysop_id_password: "/tools/sysop/:id/password",
};

export default Pathname;
