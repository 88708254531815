import ServiceShubetsuType, {
  serviceShubetsuTypeValueOf,
} from "../../domain/jigyosho/ServiceShubetsuType";

import { isEqual } from "lodash-es";

// FIXME: prototype -> class
function User() {}

User.prototype.hasBackOfficeMenu = function () {
  return (
    this.hasBackOfficeHojinMenu() ||
    this.hasBackOfficeJigyoshoMenu() ||
    this.hasBackOfficeAgentCollectionMenu()
  );
};

User.prototype.hasBackOfficeHojinMenu = function () {
  return (
    authorities(this).filter((authority) => authority.match("HOJIN$")).length >
    0
  );
};

User.prototype.hasBackOfficeJigyoshoMenu = function () {
  return (
    authorities(this).filter((authority) => authority.match("JIGYOSHO$"))
      .length > 0
  );
};

User.prototype.hasBackOfficeAgentCollectionMenu = function () {
  return this.group.hasAgentCenter;
};

User.prototype.hasBackOfficeNotificationMenu = function () {
  return (
    authorities(this).filter((authority) => authority.match("INFORMATION$"))
      .length > 0
  );
};

User.prototype.hasBackOfficeBannerMenu = function () {
  return (
    authorities(this).filter((authority) => authority.match("BANNER$")).length >
    0
  );
};

User.prototype.canAddHojin = function () {
  return authorities(this).includes("ADD_HOJIN");
};

User.prototype.canEditHojin = function () {
  return authorities(this).includes("EDIT_HOJIN");
};

User.prototype.canRemoveHojin = function () {
  return authorities(this).includes("REMOVE_HOJIN");
};

User.prototype.canEditHojinAdmin = function () {
  return this.canAddHojin();
};

User.prototype.canChangeHojinAdminPassword = function () {
  return this.canAddHojin();
};

User.prototype.canAddJigyosho = function () {
  return authorities(this).includes("ADD_JIGYOSHO");
};

User.prototype.canEditJigyosho = function () {
  return authorities(this).includes("EDIT_JIGYOSHO");
};

User.prototype.canEditJigyoshoHojin = function () {
  return authorities(this).includes("EDIT_JIGYOSHO_HOJIN");
};

User.prototype.canRemoveJigyosho = function () {
  return authorities(this).includes("REMOVE_JIGYOSHO");
};

User.prototype.canActivateFeature = function () {
  return authorities(this).includes("ACTIVATE_FEATURE");
};

User.prototype.canAddJigyoshoAdmin = function () {
  return this.canAddJigyosho();
};
User.prototype.canEditJigyoshoAdmin = function () {
  return this.canEditJigyosho();
};

User.prototype.canEditJigyoshoHojinAdmin = function () {
  return this.canEditJigyoshoHojin();
};

User.prototype.canChangeJigyoshoAdminPassword = function () {
  return this.canEditJigyosho();
};

User.prototype.canCollectJigyosho = function () {
  return this.group.agentCenter;
};

User.prototype.canAddInformation = function () {
  return authorities(this).includes("ADD_INFORMATION");
};

User.prototype.canEditInformation = function () {
  return authorities(this).includes("EDIT_INFORMATION");
};

User.prototype.canRemoveInformation = function () {
  return authorities(this).includes("REMOVE_INFORMATION");
};

User.prototype.canAddNews = function () {
  return authorities(this).includes("ADD_NEWS");
};

User.prototype.canEditNews = function () {
  return authorities(this).includes("EDIT_NEWS");
};

User.prototype.canRemoveNews = function () {
  return authorities(this).includes("REMOVE_NEWS");
};

User.prototype.canRemoveComment = function () {
  return authorities(this).includes("REMOVE_COMMENT");
};

User.prototype.canViewComment = function () {
  return authorities(this).includes("VIEW_COMMENT");
};

User.prototype.canEditJushoOnly = function () {
  return authorities(this).includes("ADD_HOJIN");
};

User.prototype.canManageJobOffer = function () {
  return authorities(this).includes("MANAGE_JOB_OFFER");
};

User.prototype.canEditApplicationFormLink = function () {
  return authorities(this).includes("EDIT_APPLICATION_FORM_LINK");
};

User.prototype.canManageAccountCareStaff = function () {
  return authorities(this).includes("MANAGE_ACCOUNT_CARE_STAFF");
};

User.prototype.canEditCareStaff = function () {
  return authorities(this).includes("EDIT_CARE_STAFF");
};

User.prototype.canAddBanner = function () {
  return authorities(this).includes("ADD_BANNER");
};

User.prototype.canEditBanner = function () {
  return authorities(this).includes("EDIT_BANNER");
};

User.prototype.canRemoveBanner = function () {
  return authorities(this).includes("REMOVE_BANNER");
};

User.prototype.includeServiceShubetsuTypes = function (
  ...serviceShubetsuArray
) {
  const serviceShubetsuType = serviceShubetsuTypeValueOf(
    this.group.serviceShubetsuCode
  );

  return serviceShubetsuArray.some((it) => isEqual(it, serviceShubetsuType));
};

User.prototype.shouldRedirectToBackOffice = function () {
  return this.includeServiceShubetsuTypes(
    ServiceShubetsuType.通所介護,
    ServiceShubetsuType.認知症対応型通所介護,
    ServiceShubetsuType.療養通所介護,
    ServiceShubetsuType.通所リハビリテーション,
    ServiceShubetsuType.地域密着型通所介護,
    ServiceShubetsuType.訪問介護,
    ServiceShubetsuType.訪問入浴介護,
    ServiceShubetsuType.訪問看護,
    ServiceShubetsuType.訪問リハビリテーション,
    ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
    ServiceShubetsuType.短期入所生活介護,
    ServiceShubetsuType.短期入所療養介護医療,
    ServiceShubetsuType.短期入所療養介護老健,
    ServiceShubetsuType.活動_通いの場,
    ServiceShubetsuType.移送_介護タクシー,
    ServiceShubetsuType.生活支援_自費ヘルプ,
    ServiceShubetsuType.配食サービス,
    ServiceShubetsuType.訪問理美容,
    ServiceShubetsuType.遺品整理_生前整理,
    ServiceShubetsuType.葬儀_相続,
    ServiceShubetsuType.高齢者等終身サポート,
    ServiceShubetsuType.見守り_緊急通報,
    ServiceShubetsuType.その他便利サービス,
    ServiceShubetsuType.見守り推進事業
  );
};

function authorities(user) {
  return user.authorities
    .map((authority) => authority.authority)
    .filter((authority) => authority);
}

export function userOf(target) {
  Object.setPrototypeOf(target, User.prototype);
  return target;
}

User.prototype.canHandleMimamoriSuishinJigyo = function () {
  const { managementArea } = this.group;
  return (
    this.canAddHojin() ||
    (!managementArea
      ? false
      : managementArea.includes("大田区") &&
        [ServiceShubetsuType.地域包括支援センター]
          .map((it) => it.code)
          .includes(this.group.serviceShubetsuCode)) ||
    (!managementArea
      ? false
      : managementArea.includes("大田区") &&
        [ServiceShubetsuType.社会福祉協議会]
          .map((it) => it.code)
          .includes(this.group.serviceShubetsuCode)) ||
    this.id === 83
  ); // 大田区役所高齢福祉部高齢福祉課
};
