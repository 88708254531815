import React, { useEffect, useState } from "react";

export const Banner = () => {
  const [closedBannerIds, setClosedBannerIds] = useState([]);

  useEffect(() => {
    const storedClosedBannerIds =
      JSON.parse(sessionStorage.getItem("closedBannerIds")) || [];
    setClosedBannerIds(storedClosedBannerIds);
  }, []);

  const handleCloseBanner = (bannerId) => {
    setClosedBannerIds([...closedBannerIds, bannerId]);
    sessionStorage.setItem(
      "closedBannerIds",
      JSON.stringify([...closedBannerIds, bannerId])
    );
  };

  const bannerData = [
    // {
    //   id: "milmonetplus",
    //   url: "https://lp.plus.milmo.jp",
    //   imageUrl: "/images/banner/banner_milmonetplus.png",
    //   altText: "ミルモネットプラス",
    // },
    // {
    //   id: "milmoautomation",
    //   url: "https://lp.automation.milmo.jp",
    //   imageUrl: "/images/banner/banner_milmoautomation.png",
    //   altText: "ミルモオートメーション",
    // },
    // {
    //   id: "milmoplan",
    //   url: "https://milmoplan.welmo.co.jp/",
    //   imageUrl: "/images/banner/banner_milmoplan_chatgpt_release.png",
    //   altText: "ミルモプラン",
    // },
    {
      id: "milmorecorder",
      url: "https://lp.recorder.milmo.jp",
      imageUrl: "/images/banner/banner_milmorecorder.png",
      altText: "ミルモレコーダー",
    },
    {
      id: "250124_joint_kv",
      url: "https://us06web.zoom.us/webinar/register/WN_PoVT4BE-TeC6B9ZCQX396w",
      imageUrl: "/images/banner/banner_250124_joint_kv.png",
      altText: "250124_セミナー",
    },
  ];

  const visibleBanners = bannerData.filter(
    (banner) => !closedBannerIds.includes(banner.id)
  );

  return (
    <div
      className={`banner-container ${
        visibleBanners.length === 0 ? "hidden" : ""
      }`}
    >
      {visibleBanners.map((banner) => (
        <div key={banner.id} className="banner">
          <a href={banner.url} target="_blank" rel="noopener noreferrer">
            <img src={banner.imageUrl} alt={banner.altText} />
          </a>
          <button
            className="close-btn"
            onClick={() => handleCloseBanner(banner.id)}
          >
            &times;
          </button>
        </div>
      ))}
    </div>
  );
};
