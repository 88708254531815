const ApiPath = {
  api: "/api",
  api_authentication: "/api/authentication",
  api_authentication_expiration: `/api/authentication/expiration`,
  api_authentication_login: `/api/authentication/login`,
  api_authentication_refresh: `/api/authentication/refresh`,
  api_authentication_user: `/api/authentication/user`,
  api_authentication_agree: `/api/authentication/agree`,
  api_maintenance: "/api/maintenance",
  api_tools_ndxr: "/api/tools/ndxr",
  api_tools_jigyosho_csv: "/api/tools/jigyosho-csv",
  api_tools_sysop: "/api/tools/sysop",
  api_tools_sysop_id: "/api/tools/sysop/:id",
  api_tools_sysop_id_editform: "/api/tools/sysop/:id/editform",
  api_tools_sysop_id_password: "/api/tools/sysop/:id/password",
  api_io: "/api/io",
  api_io_files: "/api/io/files",
  api_masterdata: "/api/masterdata",
  api_masterdata_name: "/api/masterdata/:name",
  api_services: "/api/services",
  api_services_abstracts: "/api/services/abstracts",
  api_services_id: "/api/services/:id",
  api_services_id_type: "/api/services/:id/:type",
  api_services_id_flier_event: "/api/services/:id/flier/event/:fileId",
  api_folder: "/api/folder",
  api_folder_services: "/api/folder/services",
  api_folder_services_id: "/api/folder/services/:id",
  api_opendata: "/api/opendata",
  api_opendata_id: "/api/opendata/:id",
  api_accounts: "/api/accounts",
  api_accounts_blankform: "/api/accounts/blankform",
  api_accounts_id: "/api/accounts/:id",
  api_accounts_id_editform: "/api/accounts/:id/editform",
  api_accounts_id_password: "/api/accounts/:id/password",
  api_accounts_id_email: "/api/accounts/:id/email",
  api_carestaff_accounts: "/api/accounts/carestaff",
  api_accounts_carestaff_blankform: "/api/accounts/carestaff/blankform",
  api_accounts_carestaff_id: "/api/accounts/carestaff/:id",
  api_accounts_carestaff_id_editform: "/api/accounts/carestaff/:id/editform",
  api_accounts_carestaff_id_password: "/api/accounts/carestaff/:id/password",
  api_hojin: "/api/hojin",
  api_hojin_blankform: "/api/hojin/blankform",
  api_hojin_id: "/api/hojin/:id",
  api_hojin_id_editform: "/api/hojin/:id/editform",
  api_feature_hojin_id: "/api/feature/hojin/:id",
  api_feature_hojin_id_job_offer: "/api/feature/hojin/:id/job-offer",
  api_feature_hojin_id_plus: "/api/feature/hojin/:id/plus",
  api_jigyosho: "/api/jigyosho",
  api_jigyosho_blankform: "/api/jigyosho/blankform",
  api_jigyosho_id: "/api/jigyosho/:id",
  api_jigyosho_id_join: "/api/jigyosho/:id/join",
  api_jigyosho_id_status: "/api/jigyosho/:id/status",
  api_jigyosho_id_hojin: "/api/jigyosho/:id/hojin",
  api_jigyosho_id_photos: "/api/jigyosho/:id/photos",
  api_jigyosho_id_photos_id: "/api/jigyosho/:id/photos/:fileId",
  api_jigyosho_id_photos_files: "/api/jigyosho/:id/photos/files",
  api_jigyosho_id_photos_sort: "/api/jigyosho/:id/photos/sort",
  api_jigyosho_id_photos_collective: "/api/jigyosho/:id/photos/collective",
  api_jigyosho_id_fliers: "/api/jigyosho/:id/fliers",
  api_jigyosho_id_fliers_id: "/api/jigyosho/:id/fliers/:fileId",
  api_jigyosho_id_fliers_files: "/api/jigyosho/:id/fliers/files",
  api_jigyosho_id_fliers_sort: "/api/jigyosho/:id/fliers/sort",
  api_jigyosho_id_fliers_collective: "/api/jigyosho/:id/fliers/collective",
  api_jigyosho_id_attributes: "/api/jigyosho/:id/attributes",
  api_jigyosho_id_attributes_type: "/api/jigyosho/:id/attributes/:type",
  api_agent_collection: "/api/agent-collection",
  api_agent_collection_blankform: "/api/agent-collection/blankform",
  api_googlemap_apikey: "/api/googlemap/apikey",
  api_information: "/api/information",
  api_information_id: "/api/information/:id",
  api_information_id_editform: "/api/information/:id/editform",
  api_information_blankform: "/api/information/blankform",
  api_news: "/api/news",
  api_news_id: "/api/news/:id",
  api_news_id_editform: "/api/news/:id/editform",
  api_news_blankform: "/api/news/blankform",
  api_pickup: "/api/pickup",
  api_pickup_id: "/api/pickup/:id",
  api_pickup_id_editform: "/api/pickup/:id/editform",
  api_pickup_blankform: "/api/pickup/blankform",
  api_job_offer_id: "/api/job-offer/:id",
  api_job_offer_id_edit: "/api/job-offer/:id/edit",
  api_job_offer_id_approval_request: "/api/job-offer/:id/approval-request",
  api_job_offer_id_close: "/api/job-offer/:id/close",
  api_job_offer_id_approved: "/api/job-offer/:id/approve",
  api_job_offer_id_reject: "/api/job-offer/:id/reject",
  api_job_offer_jigyosho: "/api/job-offer/jigyosho",
  api_job_offer_jigyosho_id: "/api/job-offer/jigyosho/:jigyoshoId",
  api_job_offer_jigyosho_id_add: "/api/job-offer/jigyosho/:jigyoshoId/add",
  api_job_offer_jigyosho_id_photos:
    "/api/job-offer/jigyosho/:jigyoshoId/photos",
  api_job_offer_jigyosho_id_photos_id:
    "/api/job-offer/jigyosho/:jigyoshoId/photos/:photoId",
  api_comments: "/api/comments",
  api_comments_form: "/api/comments/form",
  api_comments_id: "/api/comments/:id",
  api_comments_id_form: "/api/comments/:id/form",
  api_browse_area_cities: "/api/browse-area/cities",
  api_browse_area_towns: "/api/browse-area/towns",
  api_browse_area_label_cities: "/api/browse-area/label/cities",
  api_postcode: "/api/postcode",
  api_banner: "/api/banner",
  api_banner_public: "/api/banner/public",
  api_banner_id: "/api/banner/:id",
  api_banner_id_editform: "/api/banner/:id/editform",
  api_banner_blankform: "/api/banner/blankform",
  api_banner_id_public: "/api/banner/:id/public",
};

export default ApiPath;
