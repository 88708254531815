import TsushokeiServiceCard from "./view-card/TsushokeiServiceCard";
import TsushokeiServiceTable from "./view-table/TsushokeiServiceTable";
import TaxiCard from "./view-card/TaxiCard";
import DeliveryCard from "./view-card/DeliveryCard";
import HouseworkCard from "./view-card/HouseworkCard";
import BusinessProvideOtherCard from "./view-card/BusinessProvideOtherCard";
import HomeProvideOtherCard from "./view-card/HomeProvideOtherCard";
import homeProvideOtherTable from "./view-table/homeProvideOtherTable";
import homeProvideOtherTableHead from "./view-table/homeProvideOtherTableHead";
import MimamoriSuishinJigyoCard from "./view-card/MimamoriSuishinJigyoCard";
import MimamoriSuishinJigyoTable from "./view-table/MimamoriSuishinJigyoTable";
import MimamoriSuishinJigyoTableHead from "./view-table/MimamoriSuishinJigyoTableHead";
import ServiceShubetsuCategoryType from "../../../app/domain/jigyosho/ServiceShubetsuCategoryType";
import TsushokeiServiceTableHead from "./view-table/TsushokeiServiceTableHead";
import HomonkeiServiceCard from "./view-card/HomonkeiServiceCard";
import HomonkeiServiceTable from "./view-table/HomonkeiServiceTable";
import HomonkeiServiceTableHead from "./view-table/HomonkeiServiceTableHead";
import BarberCard from "./view-card/BarberCard";
import BarberTable from "./view-table/BarberTable";
import BarberTableHead from "./view-table/BarberTableHead";
import MementoCard from "./view-card/MementoCard";
import MementoTable from "./view-table/MementoTable";
import MementoTableHead from "./view-table/MementoTableHead";
import FuneralCard from "./view-card/FuneralCard";
import FuneralTable from "./view-table/FuneralTable";
import FuneralTableHead from "./view-table/FuneralTableHead";
import LifetimeSupportCard from "./view-card/LifetimeSupportCard";
import LifetimeSupportTable from "./view-table/LifetimeSupportTable";
import LifetimeSupportTableHead from "./view-table/LifetimeSupportTableHead";
import WatchCard from "./view-card/WatchCard";
import WatchTable from "./view-table/WatchTable";
import WatchTableHead from "./view-table/WatchTableHead";
import IsoKaigoTaxiTable from "./view-table/IsoKaigoTaxiTable";
import IsoKaigoTaxiTableHead from "./view-table/IsoKaigoTaxiTableHead";
import SeikatsushienTable from "./view-table/SeikatsushienTable";
import SeikatsushienTableHead from "./view-table/SeikatsushienTableHead";
import HaishokuServiceTable from "./view-table/HaishokuServiceTable";
import HaishokuServiceTableHead from "./view-table/HaishokuServiceTableHead";
import KyotakukaigoshienjigyoshoCard from "./view-card/KyotakukaigoshienjigyoshoCard";
import KyotakukaigoshienjigyoshoTable from "./view-table/KyotakukaigoshienjigyoshoTable";
import KyotakukaigoshienjigyoshoTableHead from "./view-table/KyotakukaigoshienjigyoshoTableHead";
import ShortstayCard from "./view-card/ShortstayCard";
import ShortstayTable from "./view-table/ShortstayTable";
import ShortstayTableHead from "./view-table/ShortstayTableHead";
import HokennaiMapPrintCard from "./print/HokennaiMapPrintCard";
import HokengaiMapPrintCard from "./print/HokengaiMapPrintCard";
import KatsudokayoinobaSonotaMapPrintCard from "./print/KatsudokayoinobaSonotaMapPrintCard";
import KatsudoKayoinobaTable from "./view-table/KatsudoKayoinobaTable";
import KatsudoKayoinobaTableHead from "./view-table/KatsudoKayoinobaTableHead";

const ServiceShubetsuCategoryHeadViewType = {
  // 保険内サービス
  通所系サービス: {
    target: ServiceShubetsuCategoryType.通所系サービス,
    card: TsushokeiServiceCard,
    table: TsushokeiServiceTable,
    tableHead: TsushokeiServiceTableHead,
    mapPrintCard: HokennaiMapPrintCard,
  },
  訪問系サービス: {
    target: ServiceShubetsuCategoryType.訪問系サービス,
    card: HomonkeiServiceCard,
    table: HomonkeiServiceTable,
    tableHead: HomonkeiServiceTableHead,
    mapPrintCard: HokennaiMapPrintCard,
  },
  短期入所系サービス: {
    target: ServiceShubetsuCategoryType.短期入所系サービス,
    card: ShortstayCard,
    table: ShortstayTable,
    tableHead: ShortstayTableHead,
    mapPrintCard: HokennaiMapPrintCard,
  },
  居宅介護支援: {
    target: ServiceShubetsuCategoryType.居宅介護支援,
    card: KyotakukaigoshienjigyoshoCard,
    table: KyotakukaigoshienjigyoshoTable,
    tableHead: KyotakukaigoshienjigyoshoTableHead,
    mapPrintCard: HokennaiMapPrintCard,
  },

  // 保険外サービス
  活動_通いの場: {
    target: ServiceShubetsuCategoryType.活動_通いの場,
    card: BusinessProvideOtherCard,
    table: KatsudoKayoinobaTable,
    tableHead: KatsudoKayoinobaTableHead,
    mapPrintCard: KatsudokayoinobaSonotaMapPrintCard,
  },
  移送_介護タクシー: {
    target: ServiceShubetsuCategoryType.移送_介護タクシー,
    card: TaxiCard,
    table: IsoKaigoTaxiTable,
    tableHead: IsoKaigoTaxiTableHead,
    mapPrintCard: HokengaiMapPrintCard,
  },
  生活支援_自費ヘルプ: {
    target: ServiceShubetsuCategoryType.生活支援_自費ヘルプ,
    card: HouseworkCard,
    table: SeikatsushienTable,
    tableHead: SeikatsushienTableHead,
    mapPrintCard: HokengaiMapPrintCard,
  },
  配食サービス: {
    target: ServiceShubetsuCategoryType.配食サービス,
    card: DeliveryCard,
    table: HaishokuServiceTable,
    tableHead: HaishokuServiceTableHead,
    mapPrintCard: HokengaiMapPrintCard,
  },
  訪問理美容: {
    target: ServiceShubetsuCategoryType.訪問理美容,
    card: BarberCard,
    table: BarberTable,
    tableHead: BarberTableHead,
    mapPrintCard: HokengaiMapPrintCard,
  },
  遺品整理_生前整理: {
    target: ServiceShubetsuCategoryType.遺品整理_生前整理,
    card: MementoCard,
    table: MementoTable,
    tableHead: MementoTableHead,
    mapPrintCard: HokengaiMapPrintCard,
  },
  葬儀_相続: {
    target: ServiceShubetsuCategoryType.葬儀_相続,
    card: FuneralCard,
    table: FuneralTable,
    tableHead: FuneralTableHead,
    mapPrintCard: HokengaiMapPrintCard,
  },
  高齢者等終身サポート: {
    target: ServiceShubetsuCategoryType.高齢者等終身サポート,
    card: LifetimeSupportCard,
    table: LifetimeSupportTable,
    tableHead: LifetimeSupportTableHead,
    mapPrintCard: HokengaiMapPrintCard,
  },
  見守り_緊急通報: {
    target: ServiceShubetsuCategoryType.見守り_緊急通報,
    card: WatchCard,
    table: WatchTable,
    tableHead: WatchTableHead,
    mapPrintCard: HokengaiMapPrintCard,
  },
  その他便利サービス: {
    target: ServiceShubetsuCategoryType.その他便利サービス,
    card: HomeProvideOtherCard,
    table: homeProvideOtherTable,
    tableHead: homeProvideOtherTableHead,
    mapPrintCard: KatsudokayoinobaSonotaMapPrintCard,
  },
  見守り推進事業: {
    target: ServiceShubetsuCategoryType.見守り推進事業,
    card: MimamoriSuishinJigyoCard,
    table: MimamoriSuishinJigyoTable,
    tableHead: MimamoriSuishinJigyoTableHead,
    mapPrintCard: HokengaiMapPrintCard,
  },
};

export function serviceShubetsuCategoryHeadViewTypeValueOf(code) {
  return Object.keys(ServiceShubetsuCategoryHeadViewType)
    .map((key) => ServiceShubetsuCategoryHeadViewType[key])
    .find((type) => type.target.code == code);
}

export default ServiceShubetsuCategoryHeadViewType;
