import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import CareStaffPasswordComponent from "../../../backoffice/account/password/CareStaffPasswordComponent";
import Parametername from "../../../../app/naming/Parametername";

class CareStaffUserPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  initializeFields(props) {
    const { params } = props.match;
    const { search } = props.location;
    const searchParams = new URLSearchParams(search);

    this.id = +params.id;
    this.isFromAccountMenu =
      searchParams.get(Parametername.isFromAccountMenu) === "true";
  }

  handleBackButtonClick() {
    const { actions } = this.props;
    this.isFromAccountMenu ? actions.gotoDashboard() : actions.gotoListPage();
  }

  render() {
    const props = {
      id: this.id,
      onGoBack: this.handleBackButtonClick,
    };
    return <CareStaffPasswordComponent {...props} />;
  }
}

CareStaffUserPasswordPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CareStaffUserPasswordPage);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gotoDashboard: () => dispatch(push(Pathname.webfront)),
      gotoListPage: () => {
        dispatch(
          push({
            pathname: Pathname.backoffice_carestaff,
          })
        );
      },
    },
  };
}
