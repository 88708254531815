import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../app/naming/Pathname";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Menu } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PasswordIcon from "@material-ui/icons/VpnKey";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import FlatPrimaryButton from "../app/ui/button/FlatPrimaryButton";
import RaisedSecondaryButton from "../app/ui/button/RaisedSecondaryButton";
import { submitToRemoveByCareStaff } from "../state/account-form/actions";

class AccountMenu extends React.Component {
  constructor(props) {
    super(props);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handlePasswordButtonClick = this.handlePasswordButtonClick.bind(this);
    this.handleLogoutButtonClick = this.handleLogoutButtonClick.bind(this);
    this.handleRemoveDialogCloseButtonClick = this.handleRemoveDialogCloseButtonClick.bind(
      this
    );
    this.handleRemoveSubmitButtonClick = this.handleRemoveSubmitButtonClick.bind(
      this
    );
  }

  state = {
    removeDialogIsOpen: false,
    isDeleteButton: false,
  };

  handleEditButtonClick(e, id) {
    e.stopPropagation();
    const { actions } = this.props;
    actions.gotoEditPage(id);
  }

  handlePasswordButtonClick(e, id) {
    e.stopPropagation();
    const { actions } = this.props;
    actions.gotoPasswordPage(id);
  }

  handleLogoutButtonClick() {
    const { onLogoutClick } = this.props;
    onLogoutClick();
  }

  handleRemoveButtonClick(e, id) {
    e.stopPropagation();
    this.setState({
      removeDialogIsOpen: true,
      removeTargetId: id,
      isDeleteButton: false,
    });
  }

  handleRemoveDialogCloseButtonClick(e) {
    e.stopPropagation();
    this.setState({
      removeDialogIsOpen: false,
      removeTargetId: undefined,
    });
  }

  handleRemoveSubmitButtonClick(e) {
    e.stopPropagation();
    const { actions, submitToRemove } = this.props;
    this.setState({ isDeleteButton: true });
    submitToRemove(this.state.removeTargetId, () => {
      actions.gotoLogoutPage();
    });
  }

  render() {
    const { userId, anchorEl, accountMenuIsOpen, onClose } = this.props;
    return (
      <Fragment>
        <Menu
          anchorEl={anchorEl}
          open={accountMenuIsOpen}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
        >
          {this.renderAccountMenu(userId)}
        </Menu>
        {this.renderRemoveDialog()}
      </Fragment>
    );
  }
  renderAccountMenu(userId) {
    const { user } = this.props.authentication;
    return (
      <div>
        <List>
          {user.canEditCareStaff() && (
            <>
              <ListItem
                button
                onClick={(e) => this.handleEditButtonClick(e, userId)}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="アカウント情報変更" />
              </ListItem>
              <ListItem
                button
                onClick={(e) => this.handlePasswordButtonClick(e, userId)}
              >
                <ListItemIcon>
                  <PasswordIcon />
                </ListItemIcon>
                <ListItemText primary="パスワード変更" />
              </ListItem>
            </>
          )}
          <ListItem button onClick={this.handleLogoutButtonClick}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="ログアウト" />
          </ListItem>
          {/* 24/12/20時点ではWelmo社が削除を管理するが、将来的にユーザ自身が削除 */}
          {/*<ListItem*/}
          {/*  button*/}
          {/*  onClick={(e) => this.handleRemoveButtonClick(e, userId)}*/}
          {/*>*/}
          {/*  <ListItemIcon>*/}
          {/*    <DeleteIcon />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary="アカウント削除" />*/}
          {/*</ListItem>*/}
        </List>
      </div>
    );
  }

  renderRemoveDialog() {
    return (
      <Dialog
        onClose={this.handleRemoveDialogCloseButtonClick}
        open={this.state.removeDialogIsOpen}
      >
        <DialogTitle>アカウント削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            アカウントデータを削除します、よろしいですか？
          </DialogContentText>
          <DialogContentText>
            アカウントデータを削除した場合、このアカウントでログインすることができなくなります。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton onClick={this.handleRemoveDialogCloseButtonClick}>
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handleRemoveSubmitButtonClick}
            disabled={this.state.isDeleteButton}
          >
            削除する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }
}

AccountMenu.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  authentication: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  anchorEl: PropTypes.object.isRequired,
  accountMenuIsOpen: PropTypes.bool.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  submitToRemove: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  const searchParams = new URLSearchParams();
  searchParams.set("isFromAccountMenu", "true");
  const search = searchParams.toString();

  return {
    submitToRemove: (id, callback) => {
      dispatch(submitToRemoveByCareStaff(id, callback));
    },
    actions: {
      gotoLogoutPage: () => dispatch(push(Pathname.logout)),
      gotoEditPage: (id) => {
        dispatch(
          push({
            pathname: Pathname.backoffice_carestaff_id_edit.replace(/:id/, id),
            search,
          })
        );
      },
      gotoPasswordPage: (id) => {
        dispatch(
          push({
            pathname: Pathname.backoffice_carestaff_id_password.replace(
              /:id/,
              id
            ),
            search,
          })
        );
      },
    },
  };
}
