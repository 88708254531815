import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../state/account-form/actions";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { withInputFeedback } from "../../../../app/ui/form";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import { Card } from "@material-ui/core";
import Boundary from "../../../../app/ui/layout/Boundary";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import RaisedSecondaryButton from "../../../../app/ui/button/RaisedSecondaryButton";
import InputTypePassword from "../../../../app/ui/form/InputTypePassword";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import ShortInputWidth from "../../../../app/ui/form/ShortInputWidth";
import DummyLabel from "../../../../app/ui/form/DummyLabel";
import StaticLabel from "../../../../app/ui/form/StaticLabel";
import DummyError from "../../../../app/ui/form/DummyError";

class AccountPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  componentDidMount() {
    const { actions, id } = this.props;
    actions.requestPasswordFormByCareStaff(id);
  }

  handleBackButtonClick() {
    const { onGoBack } = this.props;
    onGoBack();
  }

  handleSaveButtonClick() {
    const { actions, accountForm, id } = this.props;
    actions.submitToChangePasswordByCareStaff(
      id,
      accountForm.form,
      this.handleBackButtonClick
    );
  }

  createBindingProps() {
    const { actions, accountForm } = this.props;
    const { form } = accountForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          {this.renderHead()}
          {this.renderNavigation()}
          {this.renderForm()}
        </SideMargin>
      </Boundary>
    );
  }

  renderHead() {
    return <BackOfficeHead>アカウントのパスワード変更</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderForm() {
    const { form, formIsLoading, formIsSubmitting } = this.props.accountForm;
    if (formIsLoading) return <CenteredCircularProgress />;
    if (!form.typeIs("password")) return null;
    const bindingProps = this.createBindingProps();
    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title="アカウント">
            <HorizontalLabelLayout labelText="ログインID" required>
              <DummyLabel name="username" {...bindingProps} />
            </HorizontalLabelLayout>
          </FormGrouping>
          <FormGrouping title="パスワード">
            <HorizontalLabelLayout labelText="パスワード" required>
              <ShortInputWidth>
                <InputTypePassword name="password" {...bindingProps} />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="パスワード(確認)" required>
              <ShortInputWidth>
                <InputTypePassword name="passwordConfirm" {...bindingProps} />
              </ShortInputWidth>
              <StaticLabel>
                ※
                パスワードは英字と数字をそれぞれ含め、半角英数字記号8文字以上で入力してください
                <br />
              </StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout>
              <DummyError name="groupId" />
            </HorizontalLabelLayout>
          </FormGrouping>
          <Right>
            <SpaceOut>
              <FlatPrimaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatPrimaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedSecondaryButton
                disabled={formIsSubmitting}
                onClick={this.handleSaveButtonClick}
              >
                変更する
              </RaisedSecondaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }
}

AccountPasswordComponent.propTypes = {
  accountForm: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  id: PropTypes.number.isRequired,
  onGoBack: PropTypes.func.isRequired,
};

export default withInputFeedback(
  connect(mapStateToProps, mapDispatchToProps)(AccountPasswordComponent)
);

function mapStateToProps(state) {
  return {
    accountForm: state.accountForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
