import React, { Fragment } from "react";
import { Route, Switch } from "react-router";
import Pathname from "../../app/naming/Pathname";
import BackOfficeDashboard from "./dashboard/BackOfficeDashboard";
import BackOfficeLayout from "./BackOfficeLayout";
import HojinRouter from "./hojin/HojinRouter";
import JigyoshoRouter from "./jigyosho/JigyoshoRouter";
import NotificationMessage from "../../app/ui/snackbar/NotificationMessage";
import AgentCollectionRouter from "./agent-collection/AgentCollectionRouter";
import InformationMessageList from "./information/InformationMessageList";
import InformationMessageDetail from "./information/InformationMessageDetail";
import NewsMessageList from "./news/NewsMessageList";
import NewsMessageDetail from "./news/NewsMessageDetail";
import PickupMessageDetail from "./news/PickupMessageDetail";
import NotificationMessageInputList from "./notification/NotificationMessageInputList";
import NotificationMessageInputDetail from "./notification/NotificationMessageInputDetail";
import JobOfferRouter from "./job-offer/JobOfferRouter";
import CareStaffUserAddPage from "../webfront/account/carestaffuser/CareStaffUserAddPage";
import CareStaffUserEditPage from "../webfront/account/carestaffuser/CareStaffUserEditPage";
import CareStaffUserPasswordPage from "../webfront/account/carestaffuser/CareStaffUserPasswordPage";
import CareStaffUserListPage from "../webfront/account/carestaffuser/CareStaffUserListPage";

class BackOfficeRouter extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Fragment>
        <BackOfficeLayout>
          <Switch>
            <Route
              path={Pathname.backoffice_information}
              component={() => (
                <Switch>
                  <Route
                    path={Pathname.backoffice_information_id}
                    component={InformationMessageDetail}
                  />
                  <Route
                    path={Pathname.backoffice_information}
                    component={InformationMessageList}
                  />
                </Switch>
              )}
            />
            <Route
              path={Pathname.backoffice_notification}
              component={() => (
                <Switch>
                  <Route
                    path={Pathname.backoffice_notification_id}
                    component={NotificationMessageInputDetail}
                  />
                  <Route
                    path={Pathname.backoffice_notification}
                    component={NotificationMessageInputList}
                  />
                </Switch>
              )}
            />
            <Route path={Pathname.backoffice_hojin} component={HojinRouter} />
            <Route
              path={Pathname.backoffice_jigyosho}
              component={JigyoshoRouter}
            />
            <Route
              path={Pathname.backoffice_agent_collection}
              component={AgentCollectionRouter}
            />
            <Route
              path={Pathname.backoffice_job_offer}
              component={JobOfferRouter}
            />
            <Route
              path={Pathname.backoffice_news}
              component={() => (
                <Switch>
                  <Route
                    path={Pathname.backoffice_pickup_id}
                    component={PickupMessageDetail}
                  />
                  <Route
                    path={Pathname.backoffice_news_id}
                    component={NewsMessageDetail}
                  />
                  <Route
                    path={Pathname.backoffice_news}
                    component={NewsMessageList}
                  />
                </Switch>
              )}
            />
            <Route
              path={Pathname.backoffice_carestaff}
              component={() => (
                <Switch>
                  <Route
                    path={Pathname.backoffice_carestaff_add}
                    component={CareStaffUserAddPage}
                  />
                  <Route
                    path={Pathname.backoffice_carestaff_id_edit}
                    component={CareStaffUserEditPage}
                  />
                  <Route
                    path={Pathname.backoffice_carestaff_id_password}
                    component={CareStaffUserPasswordPage}
                  />
                  <Route
                    path={Pathname.backoffice_carestaff}
                    component={CareStaffUserListPage}
                  />
                </Switch>
              )}
            />
            <Route path={Pathname.backoffice} component={BackOfficeDashboard} />
          </Switch>
        </BackOfficeLayout>
        <NotificationMessage />
      </Fragment>
    );
  }
}

export default BackOfficeRouter;
