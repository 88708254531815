import React from "react";
import PropTypes from "prop-types";
import SpaceOut from "../layout/SpaceOut";
import HelpIcon from "@material-ui/icons/HelpOutline";

const BackOfficeHelpItem = (props) => {
  const Icon = props.icon || HelpIcon;
  const styles = {
    icon: {
      height: 20,
      paddingBottom: 3,
      paddingRight: 2,
      verticalAlign: "middle",
    },
    text: {
      color: props.color || "black",
    },
  };
  return (
    <div>
      <SpaceOut>
        <Icon style={styles.icon} />
      </SpaceOut>
      <span style={styles.text}>{props.text}</span>
      {props.children || null}
      {props.action || null}
    </div>
  );
};

BackOfficeHelpItem.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.node.isRequired,
  children: PropTypes.node,
  action: PropTypes.node,
  color: PropTypes.node,
};

export default BackOfficeHelpItem;
