import { combineReducers } from "redux";
import accountForm from "./account-form/reducer";
import accountList from "./account-list/reducer";
import authentication from "./authentication/reducer";
import googleMap from "./google-map/reducer";
import feature from "./feature/reducer";
import hojinForm from "./hojin-form/reducer";
import hojinList from "./hojin-list/reducer";
import inputFeedback from "./input-feedback/reducer";
import jigyoshoFlier from "./jigyosho-flier/reducer";
import jigyoshoFlierCollective from "./jigyosho-flier-collective/reducer";
import jigyoshoForm from "./jigyosho-form/reducer";
import jigyoshoList from "./jigyosho-list/reducer";
import jigyoshoPhoto from "./jigyosho-photo/reducer";
import jigyoshoPhotoCollective from "./jigyosho-photo-collective/reducer";
import jigyoshoStatus from "./jigyosho-status/reducer";
import jigyoshoChangeHojinForm from "./jigyosho-change-hojin/reducer";
import jobOffer from "./job-offer/reducer";
import jobOfferForm from "./job-offer-form/reducer";
import jobOfferJigyoshoForm from "./job-offer-jigyosho-form/reducer";
import jobOfferJigyoshoList from "./job-offer-jigyosho-list/reducer";
import jobOfferJigyoshoPhoto from "./job-offer-jigyosho-photo/reducer";
import maintenance from "./maintenance/reducer";
import masterData from "./master-data/reducer";
import notificationMessage from "./notification-message/reducer";
import openDataList from "./open-data-list/reducer";
import rejectForm from "./job-offer-reject/reducer";
import serviceContent from "./service-content/reducer";
import serviceSearch from "./service-search/reducer";
import sharedFolder from "./shared-folder/reducer";
import informationForm from "./information/reducer";
import newsForm from "./news/reducer";
import commentBlankForm from "./comment/blankForm";
import commentEditForm from "./comment/editForm";
import toolsNdxr from "./tools/ndxr";
import toolsJigyoshoCsv from "./tools/jigyosho-csv";
import bannerForm from "./banner/reducer";

const reducer = combineReducers({
  accountForm,
  accountList,
  authentication,
  googleMap,
  feature,
  hojinForm,
  hojinList,
  inputFeedback,
  jigyoshoFlier,
  jigyoshoFlierCollective,
  jigyoshoForm,
  jigyoshoList,
  jigyoshoPhoto,
  jigyoshoPhotoCollective,
  jigyoshoStatus,
  jigyoshoChangeHojinForm,
  jobOffer,
  jobOfferForm,
  jobOfferJigyoshoForm,
  jobOfferJigyoshoList,
  jobOfferJigyoshoPhoto,
  maintenance,
  masterData,
  notificationMessage,
  openDataList,
  rejectForm,
  serviceContent,
  serviceSearch,
  sharedFolder,
  informationForm,
  newsForm,
  commentBlankForm,
  commentEditForm,
  toolsNdxr,
  toolsJigyoshoCsv,
  bannerForm,
});

export default reducer;
