import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as actions from "../../../../state/jigyosho-photo-collective/actions";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FileIcon from "@material-ui/icons/Note";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Dropzone from "../../../../app/ui/dropzone/Dropzone";
import Margin from "../../../../app/ui/layout/Margin";
import { IconButton, Paper, TableHead, TableRow } from "@material-ui/core";
import FixedTable from "../../../../app/ui/table/FixedTable";
import SmallPaddingTableCell from "../../../../app/ui/table/SmallPaddingTableCell";
import {
  MASTER_DATA_BUNDLE_FOR_JIGYOSHO,
  withMasterData,
  serviceShubetsuFilter,
} from "../../../../state/master-data";
import StandardSelect from "../../../../app/ui/form/StandardSelect";
import Parametername from "../../../../app/naming/Parametername";
import LinearProgressDialog from "../../../../app/ui/progress/LinearProgressDialog";
import BackOfficeHelp from "../../../../app/ui/structure/BackOfficeHelp";
import BackOfficeHelpItem from "../../../../app/ui/structure/BackOfficeHelpItem";
import Agent from "../../../../app/fundamental/agent/Agent";
import { Card } from "@material-ui/core";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import Textarea from "../../../../app/ui/form/Textarea";
import { errorsDataOf } from "../../../../app/fundamental/data/ErrorsData";
import HorizontalSmallLabelLayout from "../../../../app/ui/form/HorizontalSmallLabelLayout";

class JigyoshoPhotoCollectivePage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleRemoveButtonClick = this.handleRemoveButtonClick.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
  }

  state = {
    filesAreUploading: false,
    draggedId: undefined,
    draggedIndex: undefined,
    removeDialogIsOpen: false,
    removeTargetId: null,
    isDeleteButton: false,
  };

  initializeFields(props) {
    const { params } = props.match;
    this.id = params.id;
    const { search } = props.location;
    const searchParams = new URLSearchParams(search);
    this.serviceShubetsuCode = searchParams.get(
      Parametername.serviceShubetsuCode
    );
    this.serviceShubetsuCategoryCode = searchParams.get(
      Parametername.serviceShubetsuCategoryCode
    );
    this.prevPathname = searchParams.get(Parametername.pathname);
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.request(this.id);
  }

  handleBackButtonClick() {
    const { actions } = this.props;
    if (this.prevPathname) {
      actions.gotoPage(this.prevPathname);
    } else {
      actions.gotoStatusPage(this.id);
    }
  }

  handleDrop(files) {
    if (!files || files.length === 0) return;
    const { actions } = this.props;

    this.setState({ filesAreUploading: true });
    actions.uploadFiles([files[0]], () => {
      this.setState({ filesAreUploading: false });
    });
  }

  handleRemoveButtonClick(e, id) {
    e.stopPropagation();
    const { actions, inputFeedback } = this.props;
    inputFeedback.errors = errorsDataOf({});
    actions.removePhoto(this.id, id);
  }

  handleDragStart(e, index, id) {
    this.setState({
      draggedId: id,
    });
  }

  handleDragEnd() {
    this.setState({
      draggedId: undefined,
      draggedIndex: undefined,
    });
  }

  handleDragEnter(e, index) {
    if (!this.state.draggedId) return;
    this.setState({
      draggedIndex: index,
    });
  }

  handleDrag(e) {
    this.scrollTop(e);
  }

  scrollTop(e) {
    // IEの場合はスクロールしない
    if (Agent.isIE()) return false;
    // 上部スクロール判定範囲
    const scrollUpY = 150;
    // 下部スクロール判定範囲
    const scrollDownY = document.documentElement.clientHeight - scrollUpY;
    // マウス座標
    const clientY = e.clientY;
    // 現在のマウス位置が上部エリア内だった場合
    if (clientY < scrollUpY) {
      // スクロール速度
      const scrollSpeed = (clientY - scrollUpY) / 10;
      // スクロール処理
      window.scrollBy(0, scrollSpeed);
      // 現在のマウス位置が下部エリア内だった場合
    } else if (clientY > scrollDownY) {
      // スクロール速度
      const scrollSpeed = (clientY - scrollDownY) / 10;
      // スクロール処理
      window.scrollBy(0, scrollSpeed);
    }
  }

  handleDragDrop(e, index) {
    if (!this.state.draggedId) return;
    const { actions } = this.props;
    actions.changeSort(this.state.draggedId, index, () => {});
  }

  handleCategoryChange(id, name, value) {
    const { actions } = this.props;
    actions.changeForm(name, value, () => {});
  }

  getThumbnailIndex() {
    const { jigyoshoPhotoCollective } = this.props;
    const { form } = jigyoshoPhotoCollective;
    var thumbnailorderNo = 0;
    if (form) {
      const thumbnailForm = form["itemForms"].filter(
        (it) => it.categoryCode != ""
      )[0];
      if (thumbnailForm) {
        thumbnailorderNo = thumbnailForm.orderNo;
      }
    }
    if (thumbnailorderNo != 0) return thumbnailorderNo;
  }

  handleSaveButtonClick() {
    const { actions, jigyoshoPhotoCollective } = this.props;
    actions.submitToEditPhotoCollective(
      this.id,
      jigyoshoPhotoCollective.form,
      this.handleBackButtonClick
    );
  }

  getStyleGhotoGridRow(index) {
    const { draggedIndex } = this.state;
    return {
      backgroundColor: draggedIndex == index ? "#ffffca" : null,
      paddingTop: "5px",
      paddingBottom: "5px",
      fontSize: "0.8125rem",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.87)",
      cursor: "pointer",
    };
  }

  render() {
    return (
      <Fragment>
        <Boundary>
          <SideMargin>
            {this.renderHead()}
            {this.renderNavigation()}
            {this.renderDropzone()}
            <BackOfficeHelp>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <BackOfficeHelpItem text="写真はドラッグ＆ドロップで順番を並び替えることができます。" />
                  <BackOfficeHelpItem text="先頭の写真を検索結果表示のサムネイル画像として利用します。" />
                  <BackOfficeHelpItem>
                    撮影ガイドは
                    <a
                      href="https://drive.google.com/uc?id=1SmtzU-kXo0gYkQziqFjoaceiSJunbxnr"
                      download="ミルモネット写真撮影マニュアル.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      こちら
                    </a>
                    。
                  </BackOfficeHelpItem>
                  <BackOfficeHelpItem text="スマートフォンより本ページを開きたい方は右のQRコードを読み取りください。" />
                </div>
                <img
                  height="100"
                  width="100"
                  src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                    location.href
                  )}`}
                  alt="QR"
                />
              </div>
            </BackOfficeHelp>
            {this.renderPhotos()}
            {this.renderFormButton()}
          </SideMargin>
        </Boundary>
        <LinearProgressDialog open={this.state.filesAreUploading} />
      </Fragment>
    );
  }

  renderHead() {
    return <BackOfficeHead>写真の登録</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderDropzone() {
    return (
      <Margin top>
        <Dropzone onDrop={this.handleDrop} errorMessage explainMessage={"（PDFや動画は不可）"} />
      </Margin>
    );
  }

  createBindingProps() {
    const { form } = this.props.jigyoshoPhotoCollective;
    const { changeForm: onChange } = this.props.actions;
    return { form, onChange };
  }

  renderPhotos() {
    const { jigyoshoPhotoCollective, masterData } = this.props;
    const { form, list } = jigyoshoPhotoCollective;
    const thumbnailorderNo = this.getThumbnailIndex();
    return (
      <Margin top>
        <Paper>
          <FixedTable>
            <TableHead>
              <TableRow>
                <SmallPaddingTableCell>写真</SmallPaddingTableCell>
                <SmallPaddingTableCell>カテゴリ ／ 説明</SmallPaddingTableCell>
                <SmallPaddingTableCell style={styles.fileTypeCell}>
                  ファイルタイプ
                </SmallPaddingTableCell>
                <SmallPaddingTableCell
                  style={styles.deleteCell}
                ></SmallPaddingTableCell>
              </TableRow>
            </TableHead>
          </FixedTable>
          {list.map((photo, index) => (
            <div
              key={photo.id}
              className="boxShadow"
              style={this.getStyleGhotoGridRow(index)}
              onDragStart={(e) => this.handleDragStart(e, index, photo.id)}
              onDragEnd={(e) => this.handleDragEnd(e, index)}
              onDragEnter={(e) => this.handleDragEnter(e, index)}
              onDrag={(e) => this.handleDrag(e)}
              onDrop={(e) => this.handleDragDrop(e, index)}
              draggable={true}
            >
              <div
                className={
                  photo.orderNo === thumbnailorderNo
                    ? "img_thumbnail_labeler"
                    : null
                }
                style={styles.photoCell}
              >
                <img
                  style={styles.photo}
                  src={photo.filePath}
                  alt={photo.originalFilename}
                  title={photo.originalFilename}
                />
              </div>
              <div style={styles.categoryCell}>
                <HorizontalSmallLabelLayout labelText="" required>
                  <StandardSelect
                    name={`itemForms.${index}.categoryCode`}
                    blank
                    blankLabel="選択してください"
                    options={masterData.servicePhotoCategory.filter(
                      serviceShubetsuFilter(
                        this.serviceShubetsuCategoryCode,
                        this.serviceShubetsuCode
                      )
                    )}
                    form={form}
                    onChange={(name, value) =>
                      this.handleCategoryChange(photo.id, name, value)
                    }
                  />
                </HorizontalSmallLabelLayout>
                <HorizontalSmallLabelLayout labelText="">
                  <Textarea
                    name={`itemForms.${index}.description`}
                    characterLimit={100}
                    {...this.createBindingProps()}
                  />
                </HorizontalSmallLabelLayout>
              </div>
              <div style={styles.fileTypeCell}>
                <a href={photo.filePath} target="photo">
                  <FileIcon style={styles.icon} />
                  {photo.contentType}
                </a>
              </div>
              <div style={styles.deleteCell}>
                <IconButton
                  color="secondary"
                  onClick={(e) => this.handleRemoveButtonClick(e, photo.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </Paper>
      </Margin>
    );
  }

  renderFormButton() {
    const { listIsSubmitting } = this.props.jigyoshoPhotoCollective;
    return (
      <Card>
        <SideMargin top bottom>
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton
                disabled={listIsSubmitting}
                onClick={this.handleSaveButtonClick}
              >
                保存する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }
}

const styles = {
  photoCell: {
    width: "350px",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "table-cell",
    verticalAlign: "middle",
  },
  categoryCell: {
    width: "350px",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "table-cell",
    verticalAlign: "middle",
  },
  fileTypeCell: {
    width: "200px",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "table-cell",
    verticalAlign: "middle",
  },
  deleteCell: {
    width: "40px",
    textAlign: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "table-cell",
    verticalAlign: "middle",
  },
  photo: {
    width: "90%",
  },
  icon: {
    margin: "0 5px",
    verticalAlign: "middle",
  },
};

JigyoshoPhotoCollectivePage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  jigyoshoPhotoCollective: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  statusPathname: PropTypes.string.isRequired,
  inputFeedback: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_JIGYOSHO)(
  connect(mapStateToProps, mapDispatchToProps)(JigyoshoPhotoCollectivePage)
);

function mapStateToProps(state) {
  return {
    jigyoshoPhotoCollective: state.jigyoshoPhotoCollective,
    masterData: state.masterData,
    inputFeedback: state.inputFeedback,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoStatusPage: (id) =>
        dispatch(push(props.statusPathname.replace(/:id/, id))),
      gotoPage: (pathname) => dispatch(push(pathname)),
    },
  };
}
