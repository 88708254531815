import ServiceShubetsuCategoryType from "../../../app/domain/jigyosho/ServiceShubetsuCategoryType";

const ServiceContentType = {
  abstract: {
    name: "abstract",
    label: "概要",
    selector: (options) => options.abstract,
  },
  detail: {
    name: "detail",
    label: "...",
    dynamicLabel: (serviceShubetsuCategoryCode) => {
      switch (serviceShubetsuCategoryCode) {
        case ServiceShubetsuCategoryType.活動_通いの場.code:
        case ServiceShubetsuCategoryType.移送_介護タクシー.code:
        case ServiceShubetsuCategoryType.生活支援_自費ヘルプ.code:
        case ServiceShubetsuCategoryType.配食サービス.code:
        case ServiceShubetsuCategoryType.訪問理美容.code:
        case ServiceShubetsuCategoryType.遺品整理_生前整理.code:
        case ServiceShubetsuCategoryType.葬儀_相続.code:
        case ServiceShubetsuCategoryType.高齢者等終身サポート.code:
        case ServiceShubetsuCategoryType.見守り_緊急通報.code:
        case ServiceShubetsuCategoryType.その他便利サービス.code:
          return "メニュー";
        default:
          return "詳細";
      }
    },
    selector: (options) => options.detail,
  },
  photo: {
    name: "photo",
    label: "写真",
    selector: (options) => options.photo,
  },
  flier: {
    name: "flier",
    label: "チラシ",
    selector: (options) => options.flier,
  },
  comment: {
    name: "comment",
    label: "コメント",
    selector: (options) => options.comment,
  },
  print: {
    name: "print",
    label: "印刷",
    selector: (options) => options.print,
  },
};

export function serviceContentTypeValueOf(name) {
  return Object.keys(ServiceContentType)
    .map((key) => ServiceContentType[key])
    .find((type) => type.name === name);
}

export function serviceContentTypeValueOfOrDefault(name) {
  const type = serviceContentTypeValueOf(name);
  return type ? type : ServiceContentType.abstract;
}

export default ServiceContentType;
