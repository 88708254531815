import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../../state/banner/actions";
import Pathname from "../../../app/naming/Pathname";
import Boundary from "../../../app/ui/layout/Boundary";
import SideMargin from "../../../app/ui/layout/SideMargin";
import FlatPrimaryButton from "../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FormGrouping from "../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../app/ui/form/InputTypeText";
import { Paper, TableBody, TableHead, TableRow } from "@material-ui/core";
import Right from "../../../app/ui/layout/Right";
import SpaceOut from "../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../app/ui/button/RaisedPrimaryButton";
import FlatSecondaryButton from "../../../app/ui/button/FlatSecondaryButton";
import BackOfficeHead from "../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../app/ui/structure/BackOfficeNavigation";
import CenteredCircularProgress from "../../../app/ui/progress/CenteredCircularProgress";
import Margin from "../../../app/ui/layout/Margin";
import BackOfficeHelp from "../../../app/ui/structure/BackOfficeHelp";
import BackOfficeHelpItem from "../../../app/ui/structure/BackOfficeHelpItem";
import Dropzone from "../../../app/ui/dropzone/Dropzone";
import FixedTable from "../../../app/ui/table/FixedTable";
import SmallPaddingTableCell from "../../../app/ui/table/SmallPaddingTableCell";
import LinearProgressDialog from "../../../app/ui/progress/LinearProgressDialog";
import { IconButton } from "../../../../node_modules/@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import InputTypeToggleButton from "../../../app/ui/form/InputTypeToggleButton";
import GridRow from "../../../app/ui/grid/GridRow";
import GridCell from "../../../app/ui/grid/GridCell";
import { useState } from "react";
import { useEffect } from "react";

const BannerInputDetail = ({ match, actions, bannerForm, inputFeedback }) => {
  const initialState = {
    filesAreUploading: false,
    draggedId: undefined,
    draggedIndex: undefined,
  };

  const [id] = useState(match.params.id);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    // 詳細データ取得
    actions.requestForm(id);
  }, []);

  // 「戻る・キャンセル」クリックイベント
  const handleBackButtonClick = () => {
    actions.gotoback();
  };

  // 保存クリックイベント
  const handleSaveButtonClick = () => {
    actions.submit(bannerForm.form, handleBackButtonClick);
  };

  // ドロップイベント
  const handleDrop = (files) => {
    if (!files || !files.length) return;
    const { changeForm: onChange } = actions;
    setState((prev) => ({
      ...prev,
      filesAreUploading: true,
    }));
    actions.uploadFiles([files[0]], (data) => {
      setState((prev) => ({
        ...prev,
        filesAreUploading: false,
      }));
      const file = data.contents[0];
      const newItem = { uploadFile: file };
      onChange("items", getItems().concat([newItem]));
    });
  };

  const createBindingProps = () => {
    const { form } = bannerForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  };

  const getItems = () => {
    return [].concat(bannerForm.form.getByName("items"));
  };

  const removeItem = (index) => {
    const { changeForm: onChange } = actions;
    onChange(
      "items",
      getItems().filter((_, i) => i !== index)
    );
  };

  // 戻る
  const renderNavigation = () => {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  };

  // ドロップエリア
  const renderDropzone = () => {
    return (
      <Margin top>
        <Dropzone onDrop={handleDrop} />
      </Margin>
    );
  };

  // 画像エリア
  const renderPhotos = () => {
    return (
      <Margin top bottom>
        <Paper>
          <FixedTable>
            <TableHead>
              <TableRow>
                <SmallPaddingTableCell>添付画像</SmallPaddingTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <SmallPaddingTableCell>
                  <div style={styles.itemContainer}>
                    {getItems().map((item, i) => {
                      return renderBannerItem(i);
                    })}
                  </div>
                </SmallPaddingTableCell>
              </TableRow>
            </TableBody>
          </FixedTable>
        </Paper>
      </Margin>
    );
  };

  const renderBannerItem = (index) => {
    const item_form_name = `items.${index}`;
    const { draggedIndex } = state;
    return (
      <div key={index} style={styles.itemBlock}>
        {((onImage) => {
          const image_name = `${item_form_name}.uploadFile`;
          const file = bannerForm.form.getByName(image_name);
          return file && onImage(file);
        })((file) => (
          <div style={draggedIndex == index ? styles.draggedIndex : {}}>
            <img src={file.filePath} style={styles.img} />
            <IconButton
              onClick={() => removeItem(index)}
              style={styles.deleteIcon}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>
    );
  };

  if (bannerForm.formIsLoading) return <CenteredCircularProgress />;
  if (!bannerForm.form.typeIs("blank") && !bannerForm.form.typeIs("edit"))
    return null;
  const bindingProps = createBindingProps();
  const text = bannerForm.form.typeIs("blank")
    ? { section: "バナー追加", subsection: "新規のバナー" }
    : { section: "バナー編集", subsection: "既存のバナー" };
  return (
    <Boundary>
      <SideMargin>
        <BackOfficeHead>{text.section}</BackOfficeHead>
        {renderNavigation()}
        <Paper style={{ padding: "1px 15px 15px 15px" }}>
          <FormGrouping title={text.subsection}>
            <HorizontalLabelLayout labelText="公開">
              <GridRow>
                <GridCell>
                  <InputTypeToggleButton name="publicFlg" {...bindingProps} />
                </GridCell>
                <GridCell grow={10}></GridCell>
              </GridRow>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="URL" required>
              <InputTypeText name="transitionUrl" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="備考">
              <InputTypeText name="note" {...bindingProps} />
            </HorizontalLabelLayout>
          </FormGrouping>
          {getItems().length === 0 && renderDropzone()}
          {inputFeedback.errors.getByName("items") && (
            <BackOfficeHelp>
              <BackOfficeHelpItem
                text="画像は必ず１枚アップロードしてください。"
                color="red"
              />
            </BackOfficeHelp>
          )}
          {renderPhotos()}
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton onClick={handleSaveButtonClick}>
                保存する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </Paper>
      </SideMargin>
      <LinearProgressDialog open={state.filesAreUploading} />
    </Boundary>
  );
};

BannerInputDetail.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bannerForm: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  inputFeedback: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    bannerForm: state.bannerForm,
    inputFeedback: state.inputFeedback,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoback: () =>
        dispatch(
          push({
            pathname: Pathname.backoffice_banner,
          })
        ),
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BannerInputDetail)
);

const styles = {
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  itemBlock: {
    position: "relative",
    width: "32%",
    padding: "10px 5px",
  },
  img: {
    maxWidth: "85%",
    paddingTop: 15,
  },
  deleteIcon: {
    position: "absolute",
    top: 0,
    right: 5,
  },
  draggedIndex: {
    borderLeft: "4px solid",
  },
};
