const RoleType = {
  ROLE_SYS_ADMIN: { name: "ROLE_SYS_ADMIN", label: "システム管理者" },
  ROLE_SYS_OPERATOR: {
    name: "ROLE_SYS_OPERATOR",
    label: "システムオペレーター",
  },
  ROLE_HOJIN_ADMIN: { name: "ROLE_HOJIN_ADMIN", label: "法人管理アカウント" },
  ROLE_JIGYOSHO_ADMIN: {
    name: "ROLE_JIGYOSHO_ADMIN",
    label: "事業所管理アカウント",
  },
  ROLE_CARE_MANAGER: { name: "ROLE_CARE_MANAGER", label: "一般ユーザー" },
  ROLE_FACILITY_CARE: { name: "ROLE_FACILITY_CARE", label: "施設系" },
  ROLE_VISITING_CARE: { name: "ROLE_VISITING_CARE", label: "訪問系" },
  ROLE_CARE_STAFF: { name: "ROLE_CARE_STAFF", label: "介護スタッフ" },
};

export function roleTypeValueOf(name) {
  return Object.keys(RoleType)
    .map((key) => RoleType[key])
    .find((type) => type.name == name);
}

export default RoleType;
