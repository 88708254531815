import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import styles from "./BackOfficeNavigation.css";
import { css } from "aphrodite";
import FlatButton from "../../app/ui/button/FlatButton";
import { backOfficeMenuTypeValues } from "./BackOfficeMenuType";
import For from "../../app/ui/layout/For";

class BackOfficeNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  handleMenuClick(pathname) {
    this.props.onMenuClick(pathname);
  }

  render() {
    const menus = this.menus();
    if (menus.length < 2) return null;
    return this.renderMain(menus);
  }

  renderMain(menus) {
    return (
      <div className={css(styles.navigationContainer)}>
        <For
          in={menus}
          each={(menu) => (
            <FlatButton
              key={menu.name}
              className={`${css(styles.navigation)} ${css(
                this.pathnameStartsWith(menu.pathname)
                  ? styles.active
                  : styles.inactive
              )}`}
              onClick={() => this.handleMenuClick(menu.pathname)}
            >
              <menu.icon />
              &nbsp;{menu.label}
            </FlatButton>
          )}
        />
      </div>
    );
  }

  menus() {
    const { user } = this.props.authentication;
    return backOfficeMenuTypeValues().filter((it) => it.available(user));
  }

  pathnameStartsWith(pathname) {
    return this.props.location.pathname.startsWith(pathname);
  }
}

BackOfficeNavigation.propTypes = {
  // state
  authentication: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // action
  onMenuClick: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BackOfficeNavigation)
);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onMenuClick: (pathname) => {
      const searchParams = new URLSearchParams();
      searchParams.set("timestamp", new Date().getTime());
      const search = searchParams.toString();
      dispatch(push({ pathname, search }));
    },
  };
}
