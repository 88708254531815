import React, { Fragment } from "react";
import PropTypes from "prop-types";
import RegistrationDataEmpty from "../RegistrationDataEmpty";
import { goToJigyoshoAttributeFormButton } from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";

class LifetimeSupportDetail extends React.Component {
  render() {
    return (
      <div className="p-office-body">
        {this.menus().length ? this.renderMenus() : <RegistrationDataEmpty />}
      </div>
    );
  }

  menus() {
    return this.props.contents.blocks.lifetimeSupportMenus.menus;
  }

  renderMenus() {
    const menus = this.menus();
    if (!menus.length) return null;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">プラン</div>
        </div>

        <div className="p-office-body-table-v">
          <table>
            <thead>
              <tr>
                <th>サービス名</th>
                <th>サービス詳細</th>
                <th>料金</th>
              </tr>
            </thead>
            <tbody>
              {menus.map((menu, i) => {
                return (
                  <tr key={i}>
                    <td>{menu.serviceMei}</td>
                    <td>{menu.shosai}</td>
                    <td>{menu.ryokin}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.lifetimeSupportMenu
          )}
        </div>
      </Fragment>
    );
  }
}

LifetimeSupportDetail.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default LifetimeSupportDetail;
