import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

class InputTypeToggleButton extends React.Component {
  static checked(value) {
    if (value === "true") return true;
    if (value === "yes") return true;
    if (value === "on") return true;
    if (value === "1") return true;
    if (value === true) return true;
    if (value === 1) return true;
    return false;
  }

  render() {
    const {
      disabled,
      form,
      labelText: label,
      name,
      onChange: change,
      onKeyDown,
    } = this.props;
    const helperText = this.props.inputFeedback.errors.getByName(name);
    const formControlProps = {
      error: helperText ? true : false,
      margin: "normal",
      style: styles.formControl,
    };
    const formControlLabelProps = {
      disabled,
      label,
      name,
      onKeyDown,
      style: { ...styles.formControlLabel, ...this.props.labelStyle },
    };
    const checkboxProps = {
      checked: InputTypeToggleButton.checked(form.getByName(name)),
      color: "primary",
      onChange: (e, checked) => change(e.target.name, checked),
    };
    return (
      <FormControl {...formControlProps}>
        <FormControlLabel
          control={<Switch {...checkboxProps} />}
          {...formControlLabelProps}
        />
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }
}

const styles = {
  formControl: {
    width: "100%",
  },
  formControlLabel: {
    width: "100%",
    marginTop: -8,
  },
};

InputTypeToggleButton.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  inputFeedback: PropTypes.object.isRequired,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  labelStyle: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputTypeToggleButton);

function mapStateToProps(state) {
  return {
    inputFeedback: state.inputFeedback,
  };
}

function mapDispatchToProps() {
  return {};
}
