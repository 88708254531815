import React, { useEffect, useState } from "react";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import ApiPath from "../../app/fundamental/request/ApiPath";
import { defaultResponseHandler } from "../../app/fundamental/request";

export const Banner = () => {
  const [bannerData, setBannerData] = useState([]);
  const [closedBannerIds, setClosedBannerIds] = useState([]);

  useEffect(() => {
    const onSuccess = (json) => {
      setBannerData(json.list);
    };
    const url = ApiPath.api_banner_public;
    ApiRequest.get(url).request(defaultResponseHandler(() => {}, onSuccess));

    const storedClosedBannerIds =
      JSON.parse(sessionStorage.getItem("closedBannerIds")) || [];
    setClosedBannerIds(storedClosedBannerIds);
  }, []);

  const handleCloseBanner = (bannerId) => {
    setClosedBannerIds([...closedBannerIds, bannerId]);
    sessionStorage.setItem(
      "closedBannerIds",
      JSON.stringify([...closedBannerIds, bannerId])
    );
  };

  const visibleBanners = bannerData.filter(
    (banner) => !closedBannerIds.includes(banner.id)
  );

  return (
    <div
      className={`banner-container ${
        visibleBanners.length === 0 ? "hidden" : ""
      }`}
    >
      {visibleBanners.map((banner) => (
        <div key={banner.id} className="banner">
          <a href={banner.url} target="_blank" rel="noopener noreferrer">
            <img src={banner.imageUrl} alt={banner.altText} />
          </a>
          <button
            className="close-btn"
            onClick={() => handleCloseBanner(banner.id)}
          >
            &times;
          </button>
        </div>
      ))}
    </div>
  );
};
