import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";
import Parametername from "../../app/naming/Parametername";

const reset = () => {
  return {
    type: ActionType.RESET,
  };
};

// 一覧取得用
export const requestList = () => {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST_LIST,
    });
    getListForm(dispatch);
  };
};

// 入力フォーム取得用
export const requestForm = (id) => {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST_FORM,
    });
    if (id > 0) {
      // 編集フォーム取得用
      getEditForm(dispatch, id);
    } else {
      // BlankForm取得用
      getBlankForm(dispatch);
    }
  };
};

// 保存用
export const submit = (form, callback) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    if (form.id > 0) {
      // 更新
      postToEditAttribute(dispatch, form, callback);
    } else {
      // 新規登録
      postToAdd(dispatch, form, callback);
    }
  };
};

// 削除用
export const submitToRemove = (id, callback) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMOVE,
      payload: {
        id,
      },
    });
    dispatch({
      type: ActionType.SUBMIT,
    });
    del(dispatch, id, callback);
  };
};

// 公開用
export const submitToPublic = (id, publicFlg, callback) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.PUBLIC,
      payload: {
        id,
        publicFlg,
      },
    });
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToEditPublic(dispatch, id, publicFlg, callback);
  };
};

// ファイルアップロード用
export const uploadFiles = (files, callback) => {
  return (dispatch) => {
    const form = new FormData();
    files.forEach((file) => form.append(Parametername.files, file));
    postToUploadFiles(dispatch, form, callback);
  };
};

// 写真並べ替え
export const changeSort = (name, id, index, callback = () => {}) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_SORT,
      payload: {
        name,
        id,
        index,
      },
    });
    setTimeout(callback);
  };
};

// form再設定
export const changeForm = (name, value) => {
  return {
    type: ActionType.CHANGE_FORM,
    payload: {
      name,
      value,
    },
  };
};

// 一覧取得用
const getListForm = (dispatch) => {
  const url = ApiPath.api_banner;
  getList(dispatch, url);
};

// 編集フォーム取得用
const getEditForm = (dispatch, id) => {
  const url = ApiPath.api_banner_id_editform.replace(/:id/, id);
  getForm(dispatch, url, "edit");
};

// BlankForm取得用
const getBlankForm = (dispatch) => {
  const url = ApiPath.api_banner_blankform;
  getForm(dispatch, url, "blank");
};

const getList = (dispatch, url) => {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_LIST_FINISHED,
      payload: {
        list: json.list,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
};

const getForm = (dispatch, url, type) => {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FORM_FINISHED,
      payload: {
        form: json,
        type,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
};

const postToAdd = (dispatch, form, callback) => {
  const url = ApiPath.api_banner;
  post(dispatch, url, form, callback, "バナー情報を新規に追加しました。");
};

const postToEditAttribute = (dispatch, form, callback) => {
  const url = ApiPath.api_banner_id.replace(/:id/, form.id);
  post(dispatch, url, form, callback, "バナー情報を更新しました。");
};

// ファイルアップロード
const postToUploadFiles = (dispatch, form, callback) => {
  const url = ApiPath.api_io_files;
  post(dispatch, url, form, callback, "アップロードしました。");
};

// ファイルソート
const post = (dispatch, url, form, callback = () => {}, message) => {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(() => callback(json));
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
};

const del = (dispatch, id, callback = () => {}) => {
  const url = ApiPath.api_banner_id.replace(/:id/, id);
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(callback);
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.delete(url).request(
    withCompletionMessage(
      dispatch,
      "バナー情報を削除しました。"
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
};

const postToEditPublic = (dispatch, id, publicFlg, callback = () => {}) => {
  const url = ApiPath.api_banner_id_public.replace(/:id/, id);
  const form = {
    id,
    publicFlg,
  };

  post(
    dispatch,
    url,
    form,
    callback,
    `バナーを${publicFlg ? "公開" : "非公開に"}しました。`
  );
};
