import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  Paper,
  TableBody,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Switch,
} from "@material-ui/core";
import * as actions from "../../../state/banner/actions";
import Pathname from "../../../app/naming/Pathname";
import Boundary from "../../../app/ui/layout/Boundary";
import SideMargin from "../../../app/ui/layout/SideMargin";
import FlatPrimaryButton from "../../../app/ui/button/FlatPrimaryButton";
import AddIcon from "@material-ui/icons/Add";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import FixedTable from "../../../app/ui/table/FixedTable";
import BackOfficeHead from "../../../app/ui/structure/BackOfficeHead";
import SmallPaddingTableCell from "../../../app/ui/table/SmallPaddingTableCell";
import Right from "../../../app/ui/layout/Right";
import RaisedSecondaryButton from "../../../app/ui/button/RaisedSecondaryButton";
import Mount from "../../../app/ui/layout/Mount";
import For from "../../../app/ui/layout/For";

const BannerInputList = ({ authentication, actions, bannerForm }) => {
  const initialState = {
    removeDialogIsOpen: false,
    removeTargetId: null,
    isDeleteButton: false,
    publicDialogIsOpen: false,
    publicTargetId: null,
    publicTargetValue: false,
    isPublicButton: false,
  };

  const [state, setState] = useState(initialState);

  // バナー一覧の取得
  useEffect(() => {
    actions.requestList();
  }, []);

  // 「バナー追加」クリックイベント
  const handleBannerAddDetailClick = () => {
    actions.gotoBannerAddDetailPage();
  };

  // 「編集」クリックイベント
  const handleBannerEditClick = (id) => {
    actions.gotoBannerEditDetailPage(id);
  };

  // 削除ダイアログ
  const handleRemoveDialogOpenButtonClick = (e, id) => {
    e.stopPropagation();
    setState((prev) => ({
      ...prev,
      removeDialogIsOpen: true,
      removeTargetId: id,
      isDeleteButton: false,
    }));
  };

  const handleRemoveDialogCloseButtonClick = (e) => {
    e.stopPropagation();
    setState((prev) => ({
      ...prev,
      removeDialogIsOpen: false,
      removeTargetId: null,
    }));
  };

  const handleRemoveDialogExecButtonClick = (e) => {
    e.stopPropagation();
    setState({ isDeleteButton: true });
    actions.submitToRemove(state.removeTargetId);
    setState((prev) => ({
      ...prev,
      removeDialogIsOpen: false,
      removeTargetId: null,
    }));
  };

  // 公開ダイアログ
  const handlePublicDialogOpenButtonClick = (e, id) => {
    e.stopPropagation();
    setState((prev) => ({
      ...prev,
      publicDialogIsOpen: true,
      publicTargetId: id,
      publicTargetValue: e.target.checked,
      isPublicButton: false,
    }));
  };

  const handlePublicDialogCloseButtonClick = (e) => {
    e.stopPropagation();
    setState((prev) => ({
      ...prev,
      publicDialogIsOpen: false,
      publicTargetId: null,
      publicTargetValue: null,
    }));
  };

  const handlePublicDialogExecButtonClick = (e) => {
    e.stopPropagation();
    setState({ isPublicButton: true });
    actions.submitToPublic(
      state.publicTargetId,
      state.publicTargetValue,
      actions.requestList
    );
    setState((prev) => ({
      ...prev,
      publicDialogIsOpen: false,
      publicTargetId: null,
      publicTargetValue: null,
    }));
  };

  const { user } = authentication;
  const renderList = () => {
    const { list, listIsLoading } = bannerForm;
    if (listIsLoading) return null;
    if (!list.length) return null;
    return (
      <TableBody>
        <For
          in={list}
          each={(it) => (
            <TableRow key={it.id}>
              <SmallPaddingTableCell>{it.publicDate}</SmallPaddingTableCell>
              <SmallPaddingTableCell>{it.updateDate}</SmallPaddingTableCell>
              <SmallPaddingTableCell>
                <a
                  href={it.transitionUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={it.items.length > 0 ? it.items[0].imageUrl : ""} />
                </a>
              </SmallPaddingTableCell>
              <SmallPaddingTableCell>{it.note}</SmallPaddingTableCell>
              <SmallPaddingTableCell>
                <Right>
                  <Mount if={user.canEditBanner()}>
                    <IconButton>
                      <Switch
                        color="primary"
                        checked={it.publicFlg}
                        onChange={(e) =>
                          handlePublicDialogOpenButtonClick(e, it.id)
                        }
                      />
                    </IconButton>
                  </Mount>
                  <Mount if={user.canEditBanner()}>
                    <IconButton onClick={() => handleBannerEditClick(it.id)}>
                      <EditIcon />
                    </IconButton>
                  </Mount>
                  <Mount if={user.canRemoveBanner()}>
                    <IconButton
                      onClick={(e) =>
                        handleRemoveDialogOpenButtonClick(e, it.id)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Mount>
                </Right>
              </SmallPaddingTableCell>
            </TableRow>
          )}
        />
      </TableBody>
    );
  };

  const renderRemoveDialog = () => {
    return (
      <Dialog
        onClose={handleRemoveDialogCloseButtonClick}
        open={state.removeDialogIsOpen}
      >
        <DialogTitle>バナー情報削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            バナー情報を削除します、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton onClick={handleRemoveDialogCloseButtonClick}>
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={handleRemoveDialogExecButtonClick}
            disabled={state.isDeleteButton}
          >
            削除する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  };

  const renderPublicDialog = () => {
    return (
      <Dialog
        onClose={handlePublicDialogCloseButtonClick}
        open={state.publicDialogIsOpen}
      >
        <DialogTitle>バナー公開の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            バナーを
            {state.publicTargetValue ? "公開" : "非公開に"}
            します、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton onClick={handlePublicDialogCloseButtonClick}>
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={handlePublicDialogExecButtonClick}
            disabled={state.isPublicButton}
          >
            {state.publicTargetValue ? "公開" : "非公開に"}する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Boundary>
      <SideMargin>
        <BackOfficeHead>バナー一覧</BackOfficeHead>
        <Mount if={user.canAddBanner()}>
          <FlatPrimaryButton
            onClick={() => {
              handleBannerAddDetailClick();
            }}
          >
            <AddIcon />
            バナーを追加
          </FlatPrimaryButton>
        </Mount>
        <Paper>
          <FixedTable>
            <TableHead>
              <TableRow>
                <SmallPaddingTableCell style={{ width: 70 }}>
                  公開日
                </SmallPaddingTableCell>
                <SmallPaddingTableCell style={{ width: 70 }}>
                  更新日
                </SmallPaddingTableCell>
                <SmallPaddingTableCell style={{ width: 70 }}>
                  バナー画像
                </SmallPaddingTableCell>
                <SmallPaddingTableCell>備考</SmallPaddingTableCell>
                <SmallPaddingTableCell style={{ width: 200 }} />
              </TableRow>
            </TableHead>
            {renderList()}
          </FixedTable>
        </Paper>
        {state.removeDialogIsOpen && renderRemoveDialog()}
        {state.publicDialogIsOpen && renderPublicDialog()}
      </SideMargin>
    </Boundary>
  );
};

BannerInputList.propTypes = {
  authentication: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bannerForm: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    bannerForm: state.bannerForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoBannerAddDetailPage: () =>
        dispatch(
          push({
            pathname: Pathname.backoffice_banner_id.replace(/:id/, "add"),
          })
        ),
      gotoBannerEditDetailPage: (id) =>
        dispatch(
          push({
            pathname: Pathname.backoffice_banner_id.replace(/:id/, id),
          })
        ),
      gotoReDisplay: () =>
        dispatch(
          push({
            pathname: Pathname.backoffice_banner_id,
          })
        ),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerInputList);
