import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import AccountListComponent from "../../../backoffice/account/list/AccountListComponent";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import AddIcon from "@material-ui/icons/Add";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";

class CareStaffUserListPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handlePasswordButtonClick = this.handlePasswordButtonClick.bind(this);
  }

  initializeFields() {
    this.groupId = 6;
  }

  handleAddButtonClick() {
    const { actions } = this.props;
    actions.gotoAddPage(this.groupId);
  }

  handleEditButtonClick(id) {
    const { actions } = this.props;
    actions.gotoEditPage(id);
  }

  handlePasswordButtonClick(id) {
    const { actions } = this.props;
    actions.gotoPasswordPage(id);
  }

  render() {
    const pageProps = {
      groupId: this.groupId,
      onEditButtonClick: this.handleEditButtonClick,
      onPasswordButtonClick: this.handlePasswordButtonClick,
      opsNavigation: this.renderOpsNavigation(),
      role: null,
    };
    return <AccountListComponent {...pageProps} />;
  }

  renderOpsNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleAddButtonClick}>
          <AddIcon />
          個人アカウントを追加する
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }
}

CareStaffUserListPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CareStaffUserListPage);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  const searchParams = new URLSearchParams();
  searchParams.set("isFromAccountMenu", "false");
  const search = searchParams.toString();

  return {
    actions: {
      gotoAddPage: () => {
        dispatch(
          push({
            pathname: Pathname.backoffice_carestaff_add,
          })
        );
      },
      gotoEditPage: (id) => {
        dispatch(
          push({
            pathname: Pathname.backoffice_carestaff_id_edit.replace(/:id/, id),
            search,
          })
        );
      },
      gotoPasswordPage: (id) => {
        dispatch(
          push({
            pathname: Pathname.backoffice_carestaff_id_password.replace(
              /:id/,
              id
            ),
            search,
          })
        );
      },
    },
  };
}
