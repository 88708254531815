import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ServiceKaishi from "./ServiceKaishi";
import ServiceYoyaku from "./ServiceYoyaku";
import ServiceGyoseiJoseiHojo from "./ServiceGyoseiJoseiHojo";
import {
  MASTER_DATA_BUNDLE_FOR_HOKENGAI,
  withMasterData,
} from "../../../../../state/master-data";

class LifetimeSupportForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderTeikyoService()}
        {this.renderOption()}
        {this.renderShikakuTou()}
        {this.renderServiceKaishi()}
        {this.renderYoyaku()}
        {this.renderGyoseiJoseiHojo()}
      </Fragment>
    );
  }

  renderTeikyoService() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="提供サービス" required>
        <HorizontalLabelLayout>
          <OptionsCheckbox
            name="teikyoService"
            options={masterData.lifetimeSupport_TeikyoService}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderOption() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="オプション" recommended>
        <HorizontalLabelLayout>
          <OptionsCheckbox
            name="option"
            options={masterData.lifetimeSupport_Option}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderShikakuTou() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="資格等" recommended>
        <HorizontalLabelLayout>
          <OptionsCheckbox
            name="shikakuTou"
            options={masterData.lifetimeSupport_Shikaku}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderServiceKaishi() {
    const { bindingProps } = this.props;
    return <ServiceKaishi bindingProps={bindingProps} />;
  }

  renderYoyaku() {
    const { bindingProps } = this.props;
    return <ServiceYoyaku bindingProps={bindingProps} />;
  }

  renderGyoseiJoseiHojo() {
    const { bindingProps } = this.props;
    return <ServiceGyoseiJoseiHojo bindingProps={bindingProps} />;
  }
}

LifetimeSupportForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOKENGAI)(
  connect(mapStateToProps, mapDispatchToProps)(LifetimeSupportForm)
);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
