import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import CareStaffAddComponent from "../../../backoffice/account/add/CareStaffAddComponent";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";

class CareStaffUserAddPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  initializeFields() {
    // 介護スタッフ用のグループIDをセット
    this.groupId = 6;
  }

  handleBackButtonClick() {
    const { actions } = this.props;
    actions.gotoListPage();
  }

  render() {
    const pageProps = {
      groupId: this.groupId,
      onGoBack: this.handleBackButtonClick,
    };
    return (
      <Boundary>
        <SideMargin>
          <CareStaffAddComponent {...pageProps} />
        </SideMargin>
      </Boundary>
    );
  }
}

CareStaffUserAddPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CareStaffUserAddPage);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gotoListPage: () => {
        dispatch(
          push({
            pathname: Pathname.backoffice_carestaff,
          })
        );
      },
    },
  };
}
