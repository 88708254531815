import SearchConditionFunnelType, {
  searchConditionFunnelTypeValues,
} from "./SearchConditionFunnelType";
import ServiceShubetsuCategoryType from "../../../app/domain/jigyosho/ServiceShubetsuCategoryType";
import ServiceShubetsuType from "../../../app/domain/jigyosho/ServiceShubetsuType";

const SearchConditionFunnelTypeConfiguration = {
  サービスの種類_介護タクシー: {
    target: SearchConditionFunnelType.サービスの種類_介護タクシー,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.移送_介護タクシー,
    ],
  },
  提供サービス_通所系サービス: {
    target: SearchConditionFunnelType.提供サービス_通所系サービス,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  提供サービス_訪問系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.提供サービス_訪問系サービス,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.訪問系サービス],
  },
  提供サービス_介護タクシー: {
    target: SearchConditionFunnelType.提供サービス_介護タクシー,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.移送_介護タクシー,
    ],
  },
  提供サービス_生活支援: {
    target: SearchConditionFunnelType.提供サービス_生活支援,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.生活支援_自費ヘルプ,
    ],
  },
  提供サービス_訪問理美容: {
    target: SearchConditionFunnelType.提供サービス_訪問理美容,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.訪問理美容],
  },
  提供サービス_遺品整理生前整理: {
    target: SearchConditionFunnelType.提供サービス_遺品整理生前整理,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.遺品整理_生前整理,
    ],
  },
  提供サービス_葬儀_相続: {
    target: SearchConditionFunnelType.提供サービス_葬儀相続,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.葬儀_相続],
  },
  提供サービス_高齢者等終身サポート: {
    target: SearchConditionFunnelType.提供サービス_高齢者等終身サポート,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.高齢者等終身サポート,
    ],
  },
  提供サービス_見守り緊急通報: {
    target: SearchConditionFunnelType.提供サービス_見守り緊急通報,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.見守り_緊急通報],
  },
  営業日: {
    target: SearchConditionFunnelType.営業日,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.居宅介護支援,
      ServiceShubetsuCategoryType.移送_介護タクシー,
      ServiceShubetsuCategoryType.生活支援_自費ヘルプ,
      ServiceShubetsuCategoryType.配食サービス,
      ServiceShubetsuCategoryType.見守り_緊急通報,
      ServiceShubetsuCategoryType.活動_通いの場,
      ServiceShubetsuCategoryType.訪問理美容,
      ServiceShubetsuCategoryType.遺品整理_生前整理,
      ServiceShubetsuCategoryType.葬儀_相続,
      ServiceShubetsuCategoryType.高齢者等終身サポート,
      ServiceShubetsuCategoryType.その他便利サービス,
      ServiceShubetsuCategoryType.見守り推進事業,
    ],
  },
  営業日_通所系サービス: {
    target: SearchConditionFunnelType.営業日_通所系サービス,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  営業日_訪問系サービス: {
    target: SearchConditionFunnelType.営業日_訪問系サービス,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.訪問系サービス],
  },
  サービス提供時間: {
    target: SearchConditionFunnelType.サービス提供時間,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  利用可能時間: {
    target: SearchConditionFunnelType.利用可能時間,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  時間外対応: {
    target: SearchConditionFunnelType.時間外対応,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  時間外対応_24時間対応あり: {
    target: SearchConditionFunnelType.時間外対応_24時間対応あり,
  },
  時間外対応_訪問系サービス: {
    target: SearchConditionFunnelType.時間外対応_訪問系サービス,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.訪問系サービス],
  },
  サービス対象者_通所系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.サービス対象者_通所系サービス,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  サービス対象者_訪問系サービス: {
    target: SearchConditionFunnelType.サービス対象者_訪問系サービス,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.訪問系サービス],
  },
  定員: {
    target: SearchConditionFunnelType.定員,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  連絡対応可能時間_居宅介護支援: {
    target: SearchConditionFunnelType.連絡対応可能時間_居宅介護支援,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.居宅介護支援],
  },
  連絡対応可能時間_訪問対応可能日: {
    target: SearchConditionFunnelType.訪問対応可能日_居宅介護支援,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.居宅介護支援],
  },
  連絡対応可能時間_取得加算: {
    target: SearchConditionFunnelType.取得加算_居宅介護支援,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.居宅介護支援],
  },
  スタッフ_居宅介護支援: {
    target: SearchConditionFunnelType.スタッフ_居宅介護支援,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.居宅介護支援],
  },
  介護予防支援_居宅介護支援: {
    target: SearchConditionFunnelType.介護予防支援_居宅介護支援,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.居宅介護支援],
  },
  窓口営業日_短期入所系サービス: {
    target: SearchConditionFunnelType.窓口営業日_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  サービス対象者_短期入所系サービス: {
    target: SearchConditionFunnelType.サービス対象者_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  実施事業者_短期入所系サービス: {
    target: SearchConditionFunnelType.実施事業者_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  居室_通所系サービス: {
    target: SearchConditionFunnelType.居室_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  写真登録: {
    target: SearchConditionFunnelType.写真登録,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.通所系サービス,
      ServiceShubetsuCategoryType.訪問系サービス,
      ServiceShubetsuCategoryType.短期入所系サービス,
      ServiceShubetsuCategoryType.居宅介護支援,
      ServiceShubetsuCategoryType.移送_介護タクシー,
      ServiceShubetsuCategoryType.生活支援_自費ヘルプ,
      ServiceShubetsuCategoryType.配食サービス,
      ServiceShubetsuCategoryType.見守り_緊急通報,
      ServiceShubetsuCategoryType.活動_通いの場,
      ServiceShubetsuCategoryType.訪問理美容,
      ServiceShubetsuCategoryType.遺品整理_生前整理,
      ServiceShubetsuCategoryType.葬儀_相続,
      ServiceShubetsuCategoryType.高齢者等終身サポート,
      ServiceShubetsuCategoryType.その他便利サービス,
    ],
  },
  昼食料金: {
    aditional: true,
    target: SearchConditionFunnelType.昼食料金,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  スタッフ: {
    target: SearchConditionFunnelType.スタッフ,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  入浴: {
    aditional: true,
    target: SearchConditionFunnelType.入浴,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  個別機能訓練: {
    aditional: true,
    target: SearchConditionFunnelType.個別機能訓練,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  送迎: {
    aditional: true,
    target: SearchConditionFunnelType.送迎,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  特化分野: {
    aditional: true,
    target: SearchConditionFunnelType.特化分野,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.通所系サービス],
  },
  配達ができる食事: {
    target: SearchConditionFunnelType.配達ができる食事,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.配食サービス],
  },

  メニュー区分: {
    target: SearchConditionFunnelType.メニュー区分,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.配食サービス],
  },

  対応してくれるサービス_配食サービス: {
    target: SearchConditionFunnelType.対応してくれるサービス_配食サービス,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.配食サービス],
  },

  対応してくれるサービス_生活支援: {
    target: SearchConditionFunnelType.対応してくれるサービス_生活支援,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.生活支援_自費ヘルプ,
    ],
  },

  対応状況_訪問理美容: {
    target: SearchConditionFunnelType.対応状況_訪問理美容,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.訪問理美容],
  },

  オプション_遺品整理生前整理: {
    target: SearchConditionFunnelType.オプション_遺品整理生前整理,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.遺品整理_生前整理,
    ],
  },
  オプション_葬儀相続: {
    target: SearchConditionFunnelType.オプション_葬儀相続,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.葬儀_相続],
  },
  オプション_高齢者等終身サポート: {
    target: SearchConditionFunnelType.オプション_高齢者等終身サポート,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.高齢者等終身サポート,
    ],
  },

  一体型連携型_訪問系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.一体型連携型_訪問系サービス,
    serviceShubetsuCategoryTypes: [
      //ServiceShubetsuCategoryType.訪問系サービス,
    ],
    serviceShubetsuTypes: [
      //ServiceShubetsuType.訪問介護,
      //ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
    ],
  },
  特化分野_訪問系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.特化分野_訪問系サービス,
    serviceShubetsuCategoryTypes: [
      //ServiceShubetsuCategoryType.訪問系サービス
    ],
    serviceShubetsuTypes: [
      //ServiceShubetsuType.訪問介護,
      //ServiceShubetsuType.訪問看護,
    ],
  },
  実施事業者_訪問系サービス: {
    target: SearchConditionFunnelType.実施事業者_訪問系サービス,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.訪問系サービス],
    serviceShubetsuTypes: [
      ServiceShubetsuType.訪問看護,
      ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
    ],
  },
  併設サービス_訪問系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.併設サービス_訪問系サービス,
    serviceShubetsuCategoryTypes: [
      //ServiceShubetsuCategoryType.訪問系サービス
    ],
  },
  訪問手段_訪問系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.訪問手段_訪問系サービス,
    serviceShubetsuCategoryTypes: [
      //ServiceShubetsuCategoryType.訪問系サービス
    ],
  },
  提供保険外サービス_訪問系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.提供保険外サービス_訪問系サービス,
    serviceShubetsuCategoryTypes: [
      //ServiceShubetsuCategoryType.訪問系サービス
    ],
  },
  入浴サービス_訪問系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.入浴サービス_訪問系サービス,
    serviceShubetsuCategoryTypes: [
      //ServiceShubetsuCategoryType.訪問系サービス
    ],
  },
  対応可能疾患_訪問系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.対応可能疾患_訪問系サービス,
    serviceShubetsuCategoryTypes: [
      //ServiceShubetsuCategoryType.訪問系サービス
    ],
  },
  対応可能医療行為_訪問系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.対応可能医療行為_訪問系サービス,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.訪問系サービス],
  },
  スタッフ_訪問系サービス: {
    target: SearchConditionFunnelType.スタッフ_訪問系サービス,
    serviceShubetsuCategoryTypes: [
      //ServiceShubetsuCategoryType.訪問系サービス
    ],
  },
  取得加算_訪問系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.取得加算_訪問系サービス,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.訪問系サービス],
  },
  提供サービス_短期入所系サービス: {
    target: SearchConditionFunnelType.提供サービス_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  緊急時の受け入れ_短期入所系サービス: {
    target: SearchConditionFunnelType.緊急時の受け入れ_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  スタッフ_短期入所系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.スタッフ_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  取得加算_短期入所系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.取得加算_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  対応可能医療行為_短期入所系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.対応可能医療行為_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  対応可能疾患_短期入所系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.対応可能疾患_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  入浴サービス_短期入所系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.入浴サービス_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  個別機能訓練_短期入所系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.個別機能訓練_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  送迎_短期入所系サービス: {
    aditional: true,
    target: SearchConditionFunnelType.送迎_短期入所系サービス,
    serviceShubetsuCategoryTypes: [
      ServiceShubetsuCategoryType.短期入所系サービス,
    ],
  },
  業種: {
    target: SearchConditionFunnelType.業種,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.見守り推進事業],
  },
  見守り内容: {
    target: SearchConditionFunnelType.見守り内容,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.見守り推進事業],
  },
  活動内容種類_活動通いの場: {
    target: SearchConditionFunnelType.活動内容種類_活動通いの場,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.活動_通いの場],
  },
  団体種別_活動通いの場: {
    target: SearchConditionFunnelType.団体種別_活動通いの場,
    serviceShubetsuCategoryTypes: [ServiceShubetsuCategoryType.活動_通いの場],
  },
};

export function searchConditionFunnelTypeValuesOfMenus(
  serviceShubetsuCategoryCode,
  serviceShubetsuMenuCodes,
  aditional = false
) {
  const config = Object.keys(SearchConditionFunnelTypeConfiguration).map(
    (key) => SearchConditionFunnelTypeConfiguration[key]
  );
  return searchConditionFunnelTypeValues()
    .map((type) => config.find((it) => it.target.name == type.name))
    .filter((type) => {
      const categoryTypes = type.serviceShubetsuCategoryTypes || [];
      const shubetsuTypes = type.serviceShubetsuTypes || [];
      if (categoryTypes.length == 0 && shubetsuTypes.length == 0) return false;
      return (
        (categoryTypes.length == 0 ||
          categoryTypes.filter((it) => it.code == serviceShubetsuCategoryCode)
            .length > 0) &&
        (shubetsuTypes.length == 0 ||
          shubetsuTypes.filter((it) =>
            serviceShubetsuMenuCodes.includes(it.code)
          ).length > 0)
      );
    })
    .filter((type) => (aditional ? true : type.aditional !== true))
    .map((type) => type.target);
}

export default SearchConditionFunnelTypeConfiguration;
