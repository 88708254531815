import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../state/account-form/actions";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import { Card } from "@material-ui/core";
import Boundary from "../../../../app/ui/layout/Boundary";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import ShortInputWidth from "../../../../app/ui/form/ShortInputWidth";
import InputTypeText from "../../../../app/ui/form/InputTypeText";
import GridRow from "../../../../app/ui/grid/GridRow";
import GridCell from "../../../../app/ui/grid/GridCell";
import StaticLabel from "../../../../app/ui/form/StaticLabel";
import FormGroupingDeleteButton from "../../../../app/ui/button/FormGroupingDeleteButton";
import StandardSelect from "../../../../app/ui/form/StandardSelect";
import InputTypeDate from "../../../../app/ui/form/InputTypeDate";
import OptionsRadio from "../../../../app/ui/form/OptionsRadio";
import {
  MASTER_DATA_BUNDLE_FOR_CARE_STAFF,
  withMasterData,
} from "../../../../state/master-data";

class CareStaffEditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleShikakuItemAdded = this.handleShikakuItemAdded.bind(this);
    this.handleShikakuItemRemoved = this.handleShikakuItemRemoved.bind(this);
  }

  componentDidMount() {
    const { actions, id } = this.props;
    actions.requestEditFormByCareStaff(id);
  }

  handleBackButtonClick() {
    const { onGoBack } = this.props;
    onGoBack();
  }

  handleSaveButtonClick() {
    const { actions, accountForm, id } = this.props;

    actions.submitToEditByCareStaff(
      id,
      accountForm.form,
      this.handleBackButtonClick
    );
  }

  createBindingProps() {
    const { actions, accountForm } = this.props;
    const { form } = accountForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  }

  handleShikakuItemAdded(bindingProps) {
    this.appendShikakuItem(
      {
        careQualificationCode: "",
        careQualificationNumber: "",
      },
      bindingProps
    );
  }

  handleShikakuItemRemoved(index, bindingProps) {
    this.removeShikakuItem(index, bindingProps);
  }

  getShikakuItems(bindingProps) {
    return [].concat(bindingProps.form.getByName("itemForms"));
  }

  appendShikakuItem(form, bindingProps) {
    const org = this.getShikakuItems(bindingProps);
    bindingProps.onChange("itemForms", org.concat([form]));
  }

  removeShikakuItem(index, bindingProps) {
    const org = this.getShikakuItems(bindingProps);
    bindingProps.onChange(
      "itemForms",
      org.filter((_, i) => i != index)
    );
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          {this.renderHead()}
          {this.renderNavigation()}
          {this.renderForm()}
        </SideMargin>
      </Boundary>
    );
  }

  renderHead() {
    return <BackOfficeHead>個人アカウント編集</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderForm() {
    const { form, formIsLoading, formIsSubmitting } = this.props.accountForm;
    const { user } = this.props.authentication;
    const { masterData } = this.props;
    if (formIsLoading) return <CenteredCircularProgress />;
    if (!form.typeIs("edit")) return null;
    const bindingProps = this.createBindingProps();
    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title="ログイン情報">
            <HorizontalLabelLayout labelText="ログインID" required>
              <ShortInputWidth>
                <InputTypeText name="username" {...bindingProps} />
              </ShortInputWidth>
              <StaticLabel>
                ※ ログインIDは半角英数字で入力してください
                <br />
              </StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="✉ メールアドレス" required>
              <InputTypeText
                name="mailAddress"
                hintText="xxxx@xxxx.xxxx"
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          </FormGrouping>
          <FormGrouping title="アカウント情報">
            {user.canManageAccountCareStaff() &&
            form.username !== user.username ? (
              <HorizontalLabelLayout labelText="権限" required>
                <StandardSelect
                  name="role"
                  blank
                  options={masterData.careStaff_roleType}
                  {...bindingProps}
                />
              </HorizontalLabelLayout>
            ) : null}
            <HorizontalLabelLayout labelText="苗字・名前" required>
              <GridRow>
                <GridCell>
                  <SpaceOut block>
                    <InputTypeText
                      name="familyName"
                      hintText="山田"
                      {...bindingProps}
                    />
                  </SpaceOut>
                </GridCell>
                <GridCell>
                  <SpaceOut block>
                    <InputTypeText
                      name="givenName"
                      hintText="太郎"
                      {...bindingProps}
                    />
                  </SpaceOut>
                </GridCell>
                <GridCell></GridCell>
              </GridRow>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="生年月日">
              <ShortInputWidth>
                <InputTypeDate name="birthday" {...bindingProps} />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="性別">
              <OptionsRadio
                name="genderCode"
                split={6}
                options={masterData.gender}
                {...bindingProps}
              />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="☎ 電話番号">
              <ShortInputWidth>
                <InputTypeText
                  name="denwabango"
                  hintText="00-0000-0000"
                  {...bindingProps}
                />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="職種">
              <StandardSelect
                name="jobTypeCode"
                blank
                options={masterData.careStaff_jobType}
                {...bindingProps}
              />
            </HorizontalLabelLayout>
            <Fragment>
              {this.getShikakuItems(bindingProps).map((item, i) => {
                return this.renderShikakuItem(item, i, bindingProps);
              })}
              {this.renderShikakuItemAdd(bindingProps)}
            </Fragment>
          </FormGrouping>
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton
                disabled={formIsSubmitting}
                onClick={this.handleSaveButtonClick}
              >
                保存する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }

  renderShikakuItemAdd(bindingProps) {
    return (
      <FlatPrimaryButton
        onClick={() => this.handleShikakuItemAdded(bindingProps)}
        {...bindingProps}
      >
        項目を追加する
      </FlatPrimaryButton>
    );
  }

  renderShikakuItem(item, i, bindingProps) {
    const { masterData } = this.props;
    const item_form_name = `itemForms.${i}`;
    return (
      <FormGrouping
        key={i}
        title={
          <Fragment>
            資格{i + 1}
            <FormGroupingDeleteButton
              onClick={() => this.handleShikakuItemRemoved(i, bindingProps)}
            />
          </Fragment>
        }
      >
        <GridRow>
          <GridCell grow={3}>
            <SpaceOut block>
              <HorizontalLabelLayout labelText="保有資格">
                <StandardSelect
                  name={`${item_form_name}.careQualificationCode`}
                  blank
                  options={masterData.careQualification}
                  {...bindingProps}
                />
              </HorizontalLabelLayout>

              <HorizontalLabelLayout labelText="資格番号">
                <InputTypeText
                  name={`${item_form_name}.careQualificationNumber`}
                  {...bindingProps}
                />
              </HorizontalLabelLayout>
            </SpaceOut>
          </GridCell>
        </GridRow>
      </FormGrouping>
    );
  }
}

CareStaffEditComponent.propTypes = {
  authentication: PropTypes.object.isRequired,
  accountForm: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  id: PropTypes.number.isRequired,
  onGoBack: PropTypes.func.isRequired,
  masterData: PropTypes.object.isRequired,
  role: PropTypes.string,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_CARE_STAFF)(
  connect(mapStateToProps, mapDispatchToProps)(CareStaffEditComponent)
);

function mapStateToProps(state) {
  return {
    accountForm: state.accountForm,
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
