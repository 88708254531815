const ActionType = {
  RESET: "BANNER_RESET",
  REQUEST_LIST: "BANNER_LIST_REQUEST",
  REQUEST_LIST_FINISHED: "BANNER_LIST_REQUEST_FINISHED",
  REQUEST_FORM: "BANNER_REQUEST_FORM",
  REQUEST_FORM_FINISHED: "BANNER_REQUEST_FORM_FINISHED",
  CHANGE_SORT: "BANNER_CHANGE_SORT",
  CHANGE_FORM: "BANNER_CHANGE_FORM",
  REMOVE: "BANNER_REMOVE",
  PUBLIC: "BANNER_PUBLIC",
  SUBMIT: "BANNER_SBMIT",
  SUBMIT_FINISHED: "BANNER_SBMIT_FINISHED",
  SUBMIT_FAILED: "BANNER_SBMIT_FAILED",
};

export default ActionType;
