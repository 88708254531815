import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

class MainContents extends React.Component {
  render() {
    const { contents, print = false } = this.props;
    const { lifetimeSupport } = contents.blocks;

    return (
      <Fragment>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">サービスについて</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>提供サービス</th>
                    <td>{lifetimeSupport.teikyoService.join("、")}</td>
                  </tr>
                  <tr>
                    <th>オプション</th>
                    <td>{lifetimeSupport.option.join("、")}</td>
                  </tr>
                  <tr>
                    <th>資格等</th>
                    <td>{lifetimeSupport.shikaku.join("、")}</td>
                  </tr>
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.lifetimeSupport
              )}
            </div>
          </div>

          <div className="p-office-body-sep__right">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">ご利用開始について</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>ご利用開始までの流れ</th>
                    <td>
                      {
                        contents.blocks.hokengaiServiceKaishi
                          .goriyokaishimadenoNagare
                      }
                    </td>
                  </tr>
                  {print ? null : (
                    <Fragment>
                      <tr>
                        <th>ご利用可能対象者</th>
                        <td>
                          {
                            contents.blocks.hokengaiServiceKaishi
                              .serviceRiyoKanoTaishosha
                          }
                        </td>
                      </tr>
                      <tr>
                        <th>提供・対象地域</th>
                        <td>
                          {
                            contents.blocks.hokengaiServiceKaishi
                              .serviceTeikyochiiki
                          }
                        </td>
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.lifetimeSupport
              )}
            </div>
          </div>
        </div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">
                行政の助成制度や補助について
              </div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>助成制度や補助券の利用可否</th>
                    <td>
                      {
                        contents.blocks.hokengaiGyoseiJoseiHojo
                          .joseiseidoHojokennoRiyoKahi
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td>{contents.blocks.hokengaiGyoseiJoseiHojo.biko}</td>
                  </tr>
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.lifetimeSupport
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

MainContents.propTypes = {
  contents: PropTypes.object.isRequired,
  print: PropTypes.bool,
};

export default MainContents;
